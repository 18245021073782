import { Link, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../../shared/funcs";
import { useMst } from "../../../store";
import { Icon } from "../../../components/icon";
import { searchGoods } from "../../../api/product";
import { useQuery } from "react-query";
import { CACHETIME } from "../../../shared/constants";
import { checkUser } from "../../../shared/funcs";
import { observer } from "mobx-react-lite";

export const DiscountGoods = observer(() => {
  const { app, user } = useMst();

  const { isLoading, isError, data } = useQuery(
    "dashboard_discounts",
    async () => {
      try {
        const response = await searchGoods({
          paging: { page: 0, limit: 6 },
            sorting: { sortBy: "id", sortDirection: "DESC" },
            filters: {
              showDiscount: true,
              showPopular: false,
              showActive: true,
              categoryType: app.categoryType,
            },
        });
        return response.data.result;
      } catch (error) {
        return [];
      }
    },
    { cacheTime: CACHETIME, staleTime: Infinity, enabled: checkUser(user.profile) }
  );

  if (isLoading || isError || !data || (data && data.length === 0)) return null;

  return (
    <div className="mt-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-md font-bold">Акционные товары</h2>
        <Link className="flex items-center" to="/product-list?discount=1">
          Все <Icon name="chevron_right" className="w-5 h-5 fill-orange-500" />{" "}
        </Link>
      </div>

      <div className="carousel space-x-4 p-2">
        {data && data.map((item) => (
          <Entity key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
});

function Entity({ item }) {
  const navigate = useNavigate();
  return (
    <div className="carousel-item">
      <div
        className="bg-base-100 w-40 flex flex-col rounded-box border"
        onClick={() => navigate(`/goods-info/${item.id}`)}
      >
        <div className="p-4  flex justify-center">
          <img
            className="rounded max-h-28"
            src={getImageUrl(item.imageUrl, 1)}
            alt={item.name}
          />
        </div>
        <p className="mt-4 text-center h-12 text-xs text-ellipsis overflow-hidden">
          {item.name}
        </p>
      </div>
    </div>
  );
}
