import { client } from "./client";
const CONTRACTOR_API = '/api/contractors';
const CONTRACTOR_REVIEW_API = '/api/contractor-review/contractor';
const MARKETPLACE_API= '/api/marketplace';

export function searchContractor(params) {
  return client.post(`${CONTRACTOR_API}/doSearch`, params)
}

export function contractorById(id) {
  return client.get(`${CONTRACTOR_API}/${id}`);
}
 
export function searchContractorReview(id, params) {
  return client.post(`${CONTRACTOR_REVIEW_API}/${id}/doSearch`, params)
}

export function marketplaceSearch(params) {
  return client.post(`${MARKETPLACE_API}/doSearch`, params);
}

export function contractorReview(params) {
  return client.post('/api/contractor-review', params);
}
