import { makeAutoObservable } from "mobx";

class OrderStore {
  productList = [];
  constructor() {
    makeAutoObservable(this);
  }

  setProductList(list) {
    this.productList = list;
  }

  addProduct(product) {
    this.productList.push(product);
  }

  getTotalAmount() {
    let total = 0;
    this.productList.forEach((item) => {
      total += item.amount;
    });
    return total;
  }
  checkIn(productId) {
    let product = this.productList.find(
      (item) => item.goods.id === productId
    );
    return product;
  }
  getItemCount(productId) {
    let product = this.productList.find(
      (cartItem) => cartItem.goods.id === productId
    );
    return product?.count || 0;
  }
  updateCount(productId, cnt) {
    let productIdx = this.productList.findIndex(
      (item) => item.goods.id === productId
    );
    this.productList[productIdx].count = cnt;
    this.productList[productIdx].amount = this.productList[productIdx].count * this.productList[productIdx].price;

  }

  deleteItem(productId) {
    let productIdx = this.productList.findIndex(
      (item) => item.goods.id === productId
    );
    this.productList.splice(productIdx, 1);
  }
}

const orderStore = new OrderStore();

export default orderStore;
