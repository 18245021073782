import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getImageUrl } from "../../shared/funcs";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { useLoadContractorProducts } from "../../shared/hooks";
import { useMst } from "../../store";
import { Icon } from "../../components/icon";
import { goodCategories } from "../../api/product";
import clsx from "clsx";
import { PageContainer } from "../../components/page-container";
import { searchTradeMark } from "../../api/catalog";

import useSeller from "./hooks/useSeller";
import {
  SellerBackground,
  SellerCard,
} from "../../components/sellers/seller-info";
import { IconMinus, IconPlus } from "../../components/icons/icons";
import { observer } from "mobx-react-lite";
import { successToast } from "../../shared/toast";

export function SellerProductList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const parentCategoryId = searchParams.get("parentCategoryId");
  const brandId = searchParams.get("brandId");
  const search = searchParams.get("search");
  const contractorId = searchParams.get("contractorId");
  const showDiscount = Boolean(searchParams.get("discount"));
  const { data: seller } = useSeller(contractorId);
  const { app, user } = useMst();

  const [pageNumber, setPageNumber] = useState(0);
  const { products, hasMore, loading, error } = useLoadContractorProducts(
    contractorId,
    categoryId,
    brandId,
    search,
    showDiscount,
    user.profile?.contractor?.id,
    pageNumber
  );

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (parentCategoryId) {
          const response = await goodCategories({
            filters: {
              categoryType: app.categoryType,
              showParent: false,
              parentId: parentCategoryId,
            },
            paging: { page: 0, limit: 80 },
          });
          setCategories(response.data.result);
        }
      } catch (error) {
        //nothing
      }
    };
    fetchCategories();
  }, [parentCategoryId, app.categoryType]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        if (categoryId) {
          const response = await searchTradeMark({
            filters: {
              categoryType: app.categoryType,
              showActive: true,
              categoryId,
            },
            paging: { page: 0, limit: 80 },
          });
          setBrands(response.data.result);
        }
      } catch (error) {
        //nothing
      }
    };
    fetchBrands();
  }, [categoryId, app.categoryType]);

  const observer = useRef();
  const lastProductRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const showProduct = (id) => {
    navigate(`/goods-info/${id}`);
  };
  return (
    <>
      <SimpleTopNav title={seller.name} />
      {seller && <SellerBackground seller={seller} />}
      <PageContainer flex>
        <div className="w-full">{seller && <SellerCard seller={seller} />}</div>

        {!categoryId && !brandId && <SearchProducts />}
        <CategoriesList categories={categories} />
        <BrandList brands={brands} />
        {products.map((item, index) => {
          if (products.length === index + 1) {
            return (
              <ProductItem
                ref={lastProductRef}
                key={item.id}
                item={item}
                showProduct={showProduct}
                seller={seller}
              />
            );
          } else {
            return (
              <ProductItem
                key={item.id}
                item={item}
                showProduct={showProduct}
                seller={seller}
              />
            );
          }
        })}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка"}</div>
      </PageContainer>
    </>
  );
}

const ProductItem = React.forwardRef(({ item, showProduct, seller }, ref) => {
  const { cart } = useMst();
  const [showCounter, setShowCounter] = useState(false);

  const addToCart = () => {
    console.log("SELLER", seller);
    const cartItem = cart.checkIn(item.goods.id);
    if (cartItem) {
      // check item in cart
      cartItem.updateCount(cartItem.count + 1);
    } else {
      // new item
      const data = {
        good: item.goods,
        contractor: seller,
        price: item,
        count: 1,
      };
      cart.addProduct(data);
    }
    successToast("Товар добавлен")
  };
  useEffect(() => {
    const cartItem = cart.checkIn(item.goods.id);
    if (cartItem) {
      setShowCounter(true);
    }
  }, []) // eslint-disable-line
  return (
    <div
      ref={ref}
      className="w-6/12 min-h-[260px] p-[5px]"
      onClick={() => showProduct(item.goods.id)}
    >
      <div className="relative w-full h-full bg-white shadow-md rounded-md overflow-hidden">
        {item.isPopular && (
          <div className="bg-orange-500 absolute rounded-tl-md text-white text-xs p-1 flex flex-row items-center">
            <Icon name="decagram" className="h-3 w-3 fill-white mr-1" />
            Хит!
          </div>
        )}
        <img
          className="my-[20px] mx-auto h-[95px]"
          src={getImageUrl(item.goods?.imageUrl, 1)}
          alt={item.goods?.name}
        />
        <p className="text-xs text-center font-bold mb-2 h-[46px] mx-[3px] text-ellipsis overflow-hidden">
          {item.goods?.name}
        </p>

        <div className="px-1 mt-4 mb-1 h-11 flex items-center justify-between">
          <p className="font-bold text-sm price-color bottom-4 left-0 w-full px-4 text-center">
            {item.price || 0} c
          </p>
          {item.price !== 0 && !showCounter && (
            <>
              <div
                className="p-2 border rounded-md"
                onClick={(e) => {
                  e.stopPropagation();
                  addToCart();
                  setShowCounter(true);
                }}
              >
                <Icon name="cart" fill="fill-orange-500" />
              </div>
            </>
          )}
          {showCounter && <ProductCounter item={item} seller={seller} />}
        </div>
      </div>
    </div>
  );
});
const ProductCounter = observer(({ item, seller }) => {
  const { cart } = useMst();
  const handlePlus = () => {
    const cartItem = cart.checkIn(item.goods.id);
    if (cartItem) {
      // check item in cart
      cartItem.updateCount(cartItem.count + 1);
    } else {
      // new item
      const data = {
        good: item.goods,
        contractor: seller,
        price: item,
        count: 1,
      };
      cart.addProduct(data);
    }
  };
  const handleMinus = () => {
    const cartItem = cart.checkIn(item.goods.id);
    if (cartItem.count === 1) {
      // delete item
      cartItem.delete();
    } else {
      // update item
      cartItem.updateCount(cartItem.count - 1);
    }
  };
  return (
    <div className="text-sm py-2">
      <div className="flex flex-row space-x-2 w-full items-center justify-end">
        <button
          className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
          onClick={(e) => {
            e.stopPropagation();
            handleMinus();
          }}
        >
          <IconMinus />
        </button>
        <p> {cart.getItemCount(item.goods.id)} </p>
        <button
          className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
          onClick={(e) => {
            e.stopPropagation();
            handlePlus();
          }}
        >
          <IconPlus />
        </button>
      </div>
    </div>
  );
});

const CategoriesList = ({ categories }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  // const parentCategoryId = searchParams.get("parentCategoryId");

  const onSelectCategory = (id) => {
    searchParams.set("categoryId", id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (categories.length > 0 && categoryId) {
      const carousel = document.getElementById("pc");
      const target = document.getElementById(`pc_${categoryId}`);
      const left = target.offsetLeft - 50;
      carousel.scrollTo({ left: left });
    }
  }, [categories, categoryId]);

  if (categories.length === 0) return null;

  return (
    <div className="bg-white border rounded-md mb-2 w-full">
      <div id="pc" className="carousel space-x-4 p-2">
        {categories.map((item) => (
          <div
            key={item.id}
            id={`pc_${item.id}`}
            onClick={() => onSelectCategory(item.id)}
            className={clsx(
              "border rounded-md w-auto bg-white",
              "flex flex-row whitespace-nowrap py-1 p-2",
              { "bg-orange-100": +categoryId === item.id }
            )}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const BrandList = ({ brands }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = searchParams.get("brandId");
  // const parentCategoryId = searchParams.get("parentCategoryId");

  const onSelectCategory = (id) => {
    searchParams.set("brandId", id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (brands.length > 0 && brandId) {
      const carousel = document.getElementById("bc");
      const target = document.getElementById(`bc_${brandId}`);
      const left = target.offsetLeft - 50;
      carousel.scrollTo({ left: left });
    }
  }, [brands, brandId]);

  if (brands.length === 0) return null;

  return (
    <div className="bg-white border rounded-md mb-2 w-full">
      <div id="bc" className="carousel space-x-4 p-2">
        {brands.map((item) => (
          <div
            key={item.id}
            id={`bc_${item.id}`}
            onClick={() => onSelectCategory(item.id)}
            className={clsx(
              "border rounded-md w-auto bg-white",
              "flex flex-row whitespace-nowrap py-1 p-2",
              { "bg-orange-100": +brandId === item.id }
            )}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const SearchProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVal, setSearchVal] = useState();
  const search = searchParams.get("search");
  useEffect(() => {
    setSearchVal(search);
  }, [search]);
  return (
    <div className="w-full mb-2 ">
      <form
        className="flex flex-row"
        onSubmit={(e) => {
          e.preventDefault();

          const formData = new FormData(e.target);
          const search = formData.get("search");
          if (search) {
            searchParams.set("search", search);
            setSearchParams(searchParams);
          } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
          }
        }}
      >
        <input
          value={searchVal || ""}
          name="search"
          type="text"
          placeholder="Поиск товаров"
          className="input input-bordered w-full"
          onChange={(e) => setSearchVal(e.currentTarget.value)}
        />
        {searchParams.get("search") && (
          <div
            onClick={() => {
              setSearchVal(null);
              searchParams.delete("search");
              setSearchParams(searchParams);
            }}
            className="border border-stone-300 rounded-md w-12 h-12 ml-2 bg-white flex items-center justify-center"
          >
            X
          </div>
        )}
      </form>
    </div>
  );
};
