import { observer } from "mobx-react-lite";
import { useMst } from "../../store";
import { chain } from "lodash";
import { getImageUrl } from "../../shared/funcs";
import { IconDelete, IconMinus, IconPlus } from "../../components/icons/icons";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { Link, useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/page-container";

export const Cart = observer(() => {
  const { cart } = useMst();
  const navigate = useNavigate();
  const grouped = chain(cart.productList)
    .groupBy("contractor.name")
    .map((val, key) => ({
      contractor: key,
      contractorId: val[0].contractor.id,
      goods: val,
    }))
    .value();
  return (
    <>
      <SimpleTopNav title="Корзина" />

      <PageContainer>
        <div className="flex bg-white justify-between p-2 text-orange-600 rounded-md shadow mb-2">
          <span>В корзине</span>
          <span>{cart.total} с</span>
        </div>
        {grouped.map((item, index) => (
          <div
            key={`c_${index}`}
            className="shadow p-2 bg-white rounded-md mb-2"
          >
            {item.goods.map((g, index) => (
              <GoodItem key={`g_${index}`} g={g} />
            ))}
            <div className="flex flex-row space-x-1">
              <div
                className="p-2 shadow-md mt-3 mb-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                onClick={() => {
                  let contractor = item.goods[0].contractor;
                  const data = {
                    id: contractor.id,
                    name: contractor.name,
                    department: contractor.department?.id,
                    address: contractor.address,
                  };
                  cart.addContractor(data);
                  navigate(`/order?delivery=Доставка`);
                }}
              >
                Оформить заказ: {item.contractor}
              </div>

              <Link
                to={`/sellers/info/${item.contractorId}`}
                className="p-2 shadow-md mt-3 mb-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
              >
                Добавить товар
              </Link>
            </div>
          </div>
        ))}
      </PageContainer>
    </>
  );
});

const GoodItem = observer(({ g }) => {
  const handleMinus = (g) => {
    if (g.count === 1) {
      // delete item
      g.delete();
    } else {
      // update item
      g.updateCount(g.count - 1);
    }
  };
  const handlePlus = (g) => {
    g.updateCount(g.count + 1);
  };
  return (
    <div className="flex items-center pb-2 border-b-2">
      <div className="mr-2 w-24">
        <img
          src={getImageUrl(g.good.imageUrl, 1)}
          className="h-auto w-14"
          alt={g.good.name}
        />
      </div>
      <div className="w-full">
        <div className="flex justify-between">
          <div className="font-bold text-sm">{g.good.name}</div>
          <button className="h-6 w-6" type="button" onClick={() => g.delete()}>
            <IconDelete fill="fill-red-600" />
          </button>
        </div>
        <div className="h-full flex justify-between">
          <div>
            <div className="text-xs text-gray-400">{g.good.category.name}</div>
            <div className="text-sm font-bold">{g.price.price} c</div>
          </div>
          <div className="flex flex-col items-end">
            <div className="text-lg py-2">
              <div className="flex flex-row space-x-2 w-full items-center justify-end">
                <button
                  className="focus:outline-none shadow-md rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
                  onClick={() => handleMinus(g)}
                >
                  <IconMinus />
                </button>
                <p> {g.count} </p>
                <button
                  className="focus:outline-none shadow-md rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
                  onClick={() => handlePlus(g)}
                >
                  <IconPlus />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
