import { useMst } from "../store";
import { getTreeCategories } from "../api/catalog";
import { goodCategories, goodsById } from "../api/product";
import { useQuery, useInfiniteQuery } from "react-query";
import { CACHE_15_MIN, PER_PAGE } from "./constants";
import { orderById } from "../api/order";

const getZeroCategories = async (categoryType) => {
  const { data } = await goodCategories({
    paging: { page: 0, limit: 80 },
    filters: {
      showParent: true,
      showActive: true,
      categoryType: categoryType,
    },
  });
  return data.result;
};

export function useZeroCategories() {
  const { app } = useMst();
  return useQuery(
    ["catalog_zero_categories", app.categoryType],
    () => getZeroCategories(app.categoryType),
    { staleTime: Infinity, cacheTime: CACHE_15_MIN }
  );
}

export function useTreeCategories(parentId, contractorId) {
  const { app } = useMst();
  return useInfiniteQuery(
    ["tree_categories", app.categoryType, parentId, contractorId],
    async ({ pageParam = 0 }) => {
      const res = await getTreeCategories({
        paging: { page: pageParam, limit: PER_PAGE },
        filters: {
          categoryType: app.categoryType,
          parentId, 
          contractorId, 
          showActive: true,
        },
      });
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const pageCount = Math.ceil(lastPage.totalCount / PER_PAGE);
        const next = lastPage.paging.page + 1;
        return next < pageCount ? next : undefined;
      },
      cacheTime: CACHE_15_MIN, 
      staleTime: Infinity,
      retry:false
    }
  )
}

async function getGoodById(id) {
  try {
    const { data } = await goodsById(id);
    return data;
  } catch (error) {
    return undefined;
  }
}
export function useGoodById(goodId) {
  return useQuery(["good", goodId], () => getGoodById(goodId), {
    cacheTime: CACHE_15_MIN,
    staleTime: Infinity,
  });
}

async function getOrderById(id) {
  try {
    const { data } = await orderById(id);
    return data;
  } catch (error) {
    return undefined;
  }
}
export function useOrderById(id) {
  return useQuery(["order", id], () => getOrderById(id), {
    cacheTime: CACHE_15_MIN,
    staleTime: Infinity,
  });
}