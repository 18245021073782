import { useState, useEffect, useRef, useMemo } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import {IconLocationMarker} from '../icons/icons';

const ICON = L.icon({
  iconUrl: "/images/location.png",
  iconSize: [24, 32],
  iconAnchor: [24, 32],
  popupAnchor: [-12, -32],
});

export const CENTER = [42.876647, 74.601959];

export const Map = ({ position, setPosition }) => {
  const [myLocation, setMyLocation] = useState(false);

  return (
    <>
      <label htmlFor="map-modal" className="bg-white border text-center text-orange-600 active:bg-orange-50 p-2">
        На карте
      </label>
      
      <input type="checkbox" id="map-modal" className="modal-toggle" />

      <div className="modal">
        <div className="modal-box">
        <label
            htmlFor="map-modal"
            className="btn btn-sm btn-circle absolute right-2 top-2 bg-orange-400 border-orange-500"
          >
            ✕
          </label>
          <div className="mt-4">
          <MapContainer className="w-full h-[500px] sm:h-[300px]" center={position || CENTER} zoom={16}>
            <MyLocationBtn setMyLocation={setMyLocation} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker
              position={position || CENTER}
              setPosition={setPosition}
              myLocation={myLocation}
              setMyLocation={setMyLocation}
            />
          </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
};

const DraggableMarker = ({ position, setPosition, myLocation, setMyLocation }) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          setMyLocation(false);
        }
      },
    }),
    [setMyLocation, setPosition],
  );

  const map = useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      setMyLocation(false);
    },
  });

  useEffect(() => {
    if (myLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setPosition([latitude, longitude]);
        map.flyTo([latitude, longitude], map.getZoom());
      });
    }
  }, [myLocation, map, setPosition]);

  return (
    <Marker
      icon={ICON}
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    >
      <Popup minWidth={90}>
        <span>Маркер можно двигать зажав его</span>
      </Popup>
    </Marker>
  );
};

const MyLocationBtn = ({ setMyLocation }) => (
  <div className="absolute left-3 top-20 border border-slate-300 rounded-md" onClick={() => setMyLocation(true)}>
    <span className="text-orange-500"><IconLocationMarker /></span>
  </div>
);