import React from "react";
import { useMst } from "../../store";
import { observer } from "mobx-react-lite";

export const Layout = (props) => {
  return (
    <div className="container mx-auto bg-[#f9faff]">
      <Loading />

      {props.children}
    </div>
  );
};

const Loading = observer(() => {
  const { app } = useMst();
  return (
    <>
      {app.loading && <Loader />}
    </>
  );
});

function Loader() {
  return (
    <div className="fixed flex item-center justify-center inset-0 z-[99999] w-full h-screen bg-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "auto", background: "#fff", display: "block" }}
        width="48px"
        height="48px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#f4792a"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
}
