import { useQuery } from "react-query";
import { contractorById } from "../../../api/contractor";
import { CACHETIME } from "../../../shared/constants";
async function getSeller(id) {
  try {
    const { data } = await contractorById(id);
    return data;
  } catch (error) {
    return undefined;
  }
}
export default function useSeller(sellerId) {
  return useQuery(["seller", sellerId], () => getSeller(sellerId), {
    cacheTime: CACHETIME,
    staleTime: Infinity,
  });
}
