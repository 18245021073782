import React from "react";
import { TopNav } from "../../components/dashboard/top-nav";
import { useMst } from "../../store";
// import { BonusCard } from "./components/bonus-card";
import { PopularGoods } from "./components/popular-goods";
import { Slider } from "./components/slider";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { merchantAuth, standartLogin } from "../../api/user";
import { Categories } from "./components/categories";
import { Brands } from "./components/brands";
import { DiscountGoods } from "./components/discount-goos";
import { Sellers } from "./components/sellers";
import { LastOrders } from "./components/last-orders";
import { PageContainer } from "../../components/page-container";

export function Dashboard() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useMst();

  React.useEffect(() => {
    const login = () => {
      try {
        const token = searchParams.get("token");
        const categoryType = searchParams.get("categoryType");
        const contractorType = searchParams.get("contractorType");
        if (token && categoryType && contractorType) {
          user.fetchAuth(token, categoryType, contractorType);
        }
      } catch (error) {
        console.error(error);
      }
    };
    login();
  }, [searchParams, user]);

  return (
    <>
      <TopNav />
      <PageContainer>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const formData = new FormData(e.target);
            console.log(formData.get("search"));
            navigate(`/product-list?search=${formData.get("search")}`);
          }}
        >
          <input
            name="search"
            type="text"
            placeholder="Поиск товаров"
            className="input input-bordered w-full"
          />
        </form>
        <Slider />
        {/* <BonusCard /> */}

        {/* <div
          className="mt-6 w-full p-2 active:bg-blue-50 border border-blue-200 shadow-md shadow-blue-500/50 bg-base-100 rounded-full"
          onClick={() => {
            navigate("/profile/order/history");
          }}
        >
          <p className="text-center text-xl">История заказов</p>
        </div> */}

        <Categories />

        <Brands />

        <DiscountGoods />

        <Sellers />

        <LastOrders />

        <PopularGoods title="Популярные товары" />

        {/* <div
          className="mt-6 w-full p-2 shadow-md shadow-teal-500/50 bg-white active:bg-teal-50 border border-teal-200 rounded-full"
          onClick={() => {
            navigate("/faq");
          }}
        >
          <p className="text-center text-xl">Вопросы и ответы</p>
        </div> */}
      </PageContainer>
    </>
  );
}
