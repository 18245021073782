/**
 * Категории 1-го и 2-го уровня (infinite query)
 */

import React, { Fragment, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../shared/funcs";
import { useTreeCategories } from "../../shared/query-hooks";
import { useInView } from "react-intersection-observer";

export function TreeCategoryQuery({ parentId, contractorId, pathname }) {
  const { ref, inView } = useInView();

  // const [searchParams] = useSearchParams();
  const { status, data, error, fetchNextPage } = useTreeCategories(
    parentId,
    contractorId
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]); // eslint-disable-line

  return (
    <>
      {status === "loading" ? (
        <p>Загрузка...</p>
      ) : status === "error" ? (
        <span>Ошибка: {error.message}</span>
      ) : (
        <div ref={ref} className="flex flex-wrap">
          {data.pages.map((page, index) => (
            <Fragment key={index}>
              {page.result.map((prod) => (
                <Category
                  key={prod.id}
                  item={prod}
                  contractorId={contractorId}
                  pathname={pathname}
                />
              ))}
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
}
TreeCategoryQuery.defaultProps = {
  contractorId: undefined,
  pathname: "/product-list",
};

const Category = React.forwardRef(({ item, contractorId, pathname }, ref) => {
  const navigate = useNavigate();
  const toProductList = (id, parentId) => {
    const params = {
      categoryId: id,
      parentCategoryId: parentId,
      contractorId,
    };
    // clear undefined
    Object.keys(params).forEach(key => params[key] === undefined ? delete params[key] : {});

    navigate({
      pathname,
      search: createSearchParams(params).toString(),
    });
  };
  return (
    <div ref={ref} className="w-full py-4">
      <h2 className="mb-2 text-md font-bold">{item.name}</h2>
      <div className="flex flex-wrap">
        {item.children.map((subItem) => (
          <SubCategory
            key={subItem.id}
            item={subItem}
            parentId={item.id}
            onClick={toProductList}
          />
        ))}
      </div>
    </div>
  );
});

const SubCategory = ({ item, parentId, onClick }) => {
  return (
    <div
      className="w-4/12 p-[5px] h-[110px]"
      onClick={() => onClick(item.id, parentId)}
    >
      <div className="relative w-full h-full p-2 bg-white rounded-xl shadow-md overflow-hidden">
        <p className="text-xs overflow-hidden break-normal overflow-ellipsis">
          {item.name}
        </p>
        <img
          className="absolute h-[80px] w-[80px] bottom-[-10px] right-[-20px] -rotate-[30deg]"
          src={getImageUrl(item.imageUrl, 1)}
          alt={item.name}
        />
      </div>
    </div>
  );
};
