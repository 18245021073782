import {
  IconAnnotation,
  // IconBox,
  // IconBriefcase,
  // IconChevronRight,
  IconDocument,
  // IconGift,
  IconHeart,
  // IconInformationCircle,
  //IconInformationCircle, IconPencil,
  // IconQuestionMarkCircle,
  IconShare,
  // IconThumbUp,
  // IconUserAdd,
  IconX,
} from "../../components/icons/icons";
import { Link } from "react-router-dom";
import { useMst } from "../../store";
import { getImageUrl } from "../../shared/funcs";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
export function Profile() {
  const { user } = useMst();
  return (
    <>
      <SimpleTopNav title="Профиль" />
      <PageContainer>
        <div className="container items-center p-2 pb-40">
          <div className="card bg-base-100 card-compact mb-2 shadow">
            <div className="card-body info-card">
              {/* <Link to="/profile-info"> */}
              <div className="flex justify-between items-center">
                <div className="avatar w-full">
                  <div className="w-14 h-14 rounded-full mr-4">
                    <img
                      src={getImageUrl(user.profile?.contractor.imageUrl)}
                      alt="фото"
                    />
                  </div>
                  <div className="!aspect-auto w-3/5">
                    <h3 className="font-bold text-xl">
                      {user.profile?.username}
                    </h3>
                  </div>
                </div>
                {/* <div className="text-gray-400">
                  <IconChevronRight />
                </div> */}
              </div>
              {/* </Link> */}
            </div>
          </div>
          <div className="card bg-base-100 card-compact mb-2 shadow">
            <div className="card-body info-card">
              {/* <div className="flex items-center">
              <div className="text-orange-500 mr-3">
                <IconBox />
              </div>
              <div className="text-lg">Товары</div>
            </div> */}
              {/* <div className="flex items-center mt-2">
              <div className="text-orange-500 mr-3">
                <IconBriefcase />
              </div>
              <div className="text-lg">Партнеры</div>
            </div> */}
              <Link to="/profile/order/history">
                <div className="flex items-center mt-2">
                  <div className="text-orange-500 mr-3">
                    <IconDocument />
                  </div>
                  <div className="text-lg">История заказов</div>
                </div>
              </Link>
              {/* <Link to="/bonus">
                <div className="flex items-center mt-2">
                  <div className="text-orange-500 mr-3">
                    <IconGift />
                  </div>
                  <div className="text-lg">Бонусы</div>
                </div>
              </Link> */}
            </div>
          </div>
          <div className="card bg-base-100 card-compact mb-2 shadow">
            <div className="card-body info-card">
              <Link to="/favorites">
                <div className="flex items-center">
                  <div className="text-orange-500 mr-3">
                    <IconHeart />
                  </div>
                  <div className="text-lg">Избранное</div>
                </div>
              </Link>
              {/* <Link to="/rating">
                <div className="flex items-center mt-2">
                  <div className="text-orange-500 mr-3">
                    <IconThumbUp />
                  </div>
                  <div className="text-lg">Отзывы</div>
                </div>
              </Link> */}
            </div>
          </div>
          <div className="card bg-base-100 card-compact mb-2 shadow">
            <div className="card-body info-card">
              {/* <div className="flex items-center">
                <div className="text-orange-500 mr-3">
                  <IconUserAdd />
                </div>
                <label htmlFor="my-modal-share" className="text-lg">
                  Пригласить друзей
                </label>
              </div> */}
              <div className="flex items-center mt-2">
                <div className="text-orange-500 mr-3">
                  <IconAnnotation />
                </div>
                <label htmlFor="my-modal-support" className="text-lg">
                  Обратная связь
                </label>
              </div>
              {/* <Link to="/faq">
                <div className="flex items-center mt-2">
                  <div className="text-orange-500 mr-3">
                    <IconQuestionMarkCircle />
                  </div>
                  <div className="text-lg">Вопрос и ответы</div>
                </div>
              </Link> */}
            </div>
          </div>
          {/*<div className="card bg-base-100 card-compact mb-2 shadow">*/}
          {/*  <div className="card-body info-card">*/}
          {/*    <Link to="/about">*/}
          {/*      <div className="flex items-center mt-2">*/}
          {/*        <div className="text-orange-500 mr-3">*/}
          {/*          <IconInformationCircle />*/}
          {/*        </div>*/}
          {/*        <div className="text-lg">О приложении</div>*/}
          {/*      </div>*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        {/*modal share */}
        <input type="checkbox" id="my-modal-share" className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <div className="flex justify-between items-center">
              <h3 className="text-gray-400 flex items-center text-lg">
                <IconShare />
                <div className="ml-2">Поделиться</div>
              </h3>
              <label
                htmlFor="my-modal-share"
                className="text-gray-400 text-2xs"
              >
                <IconX />
              </label>
            </div>
            <div className="divider"></div>
            <div className="py-4 flex justify-around">
              <button>
                <svg className={`fill-gray-500`} width="40" height="41">
                  <use
                    xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#whatsapp`}
                  ></use>
                </svg>
              </button>
              <button>
                <svg className={`fill-gray-500`} width="40" height="40">
                  <use
                    xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#telegram`}
                  ></use>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/*modal support */}
        <input type="checkbox" id="my-modal-support" className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <div className="flex justify-between items-center">
              <h3 className="text-gray-400 flex items-center text-lg">
                <IconAnnotation />
                <div className="ml-2">Написать тех поддержке</div>
              </h3>
              <label
                htmlFor="my-modal-support"
                className="text-gray-400 text-2xs"
              >
                <IconX />
              </label>
            </div>
            <div className="divider"></div>
            <div className="py-4 flex justify-around">
              <button>
                <svg className={`fill-gray-500`} width="40" height="41">
                  <use
                    xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#whatsapp`}
                  ></use>
                </svg>
              </button>
              <button>
                <svg className={`fill-gray-500`} width="40" height="40">
                  <use
                    xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#telegram`}
                  ></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
}
