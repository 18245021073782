import clsx from "clsx";
import { forwardRef, useCallback, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { PageContainer } from "../../../components/page-container";
import { OrderStatus } from "../../../components/profile/order-status";
import { SimpleTopNav } from "../../../components/simple-top-nav";
import { dateFormat } from "../../../shared/funcs";
import { useLoadOrderHistory } from "../../../shared/hooks";
import { useMst } from "../../../store";
import { DEALS_STATUSES } from "../../../shared/constants";
import { Icon } from "../../../components/icon";
import { orderDelete } from "../../../api/order";
import { errorToast, successToast } from "../../../shared/toast";

export function OrderHistory() {
  const { user } = useMst();
  const [pageNumber, setPageNumber] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { orders, hasMore, loading, error } = useLoadOrderHistory(
    user.profile?.contractor?.id,
    status,
    pageNumber
  );

  const observer = useRef();
  const lastOrderRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const onDelete = (item) => {
    console.log("Deleted item = ", item);
    setSelectedOrder(item);
    setIsOpen(true);
  };

  const delOrder = async () => {
    try {
      await orderDelete(selectedOrder.id);
      setIsOpen(false);
      successToast('Заказ удален')
      window.location.reload();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <>
      <SimpleTopNav title="Исторя заказов" />
      <PageContainer>
        <StatusFitler />
        {orders.map((order, index) => {
          if (orders.length === index + 1) {
            return (
              <OrderItem
                ref={lastOrderRef}
                key={order.id}
                item={order}
                onDelete={onDelete}
              />
            );
          } else {
            return (
              <OrderItem key={order.id} item={order} onDelete={onDelete} />
            );
          }
        })}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка загрузки"}</div>
      </PageContainer>
      <DeleteModal isOpen={isOpen} no={() => setIsOpen(false)} yes={delOrder} />
    </>
  );
}

const OrderItem = forwardRef(({ item, onDelete }, ref) => {
  return (
    <>
      <Link to={`/profile/order/edit/${item.id}`}>
        <div
          ref={ref}
          className="bg-white border shadow-md p-2 mb-4 rounded-md relative"
        >
          <div>#{item.id} {item.supplier.name}</div>
          <div className="text-sm text-gray-400">
            {dateFormat(item.createdAt)}
          </div>
          <div className="text-sm text-gray-400">
            {dateFormat(item.receiptDate)}
          </div>
          <div>{item.totalAmount} c</div>
          <div>
            <OrderStatus status={item.status} />
          </div>
          <div
            className="absolute right-2 top-2 border rounded-md p-1 active:bg-orange-50"
            onClick={(e) => {
              e.preventDefault();
              onDelete(item);
            }}
          >
            <Icon name="trash" fill="fill-red-500" />
          </div>
        </div>
      </Link>
    </>
  );
});

const StatusFitler = () => {
  const onClick = (id) => {
    const selected = searchParams.get("status");
    if (selected === id) {
      searchParams.delete("status");
    } else {
      searchParams.set("status", id);
    }
    setSearchParams(searchParams);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="bg-white border rounded-md mb-2">
      <div id="order_status" className="carousel space-x-4 p-2">
        {DEALS_STATUSES.map((item) => (
          <div
            key={item.id}
            id={`order_status_${item.id}`}
            onClick={() => onClick(item.id)}
            className={clsx(
              "border rounded-md w-auto bg-white",
              "flex flex-row whitespace-nowrap py-1 px-2",
              { "bg-orange-100": searchParams.get("status") === item.id }
            )}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const DeleteModal = ({ isOpen, no, yes }) => {
  return (
    <div
      className={clsx("fixed z-20 overflow-y-auto top-0 w-full left-0", {
        hidden: !isOpen,
      })}
      id="modal"
    >
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-28 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="font-bold text-md px-2 py-3 text-center">
            Вы уверены, что хотите удалить заказ?
          </div>
          <div className="px-4 py-3 text-right">
            <button
              onClick={() => yes()}
              type="button"
              className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-700 mr-2"
            >
              Да
            </button>
            <button
              onClick={() => no()}
              type="button"
              className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-700 mr-2"
            >
              Нет
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
