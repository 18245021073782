export function  OrderStatus({status}) {
  let stats;
  switch (status) {
    case "Новый":
      stats = <span className="text-green-500">Новый</span>;
      break;
    case "Отправлен":
      stats = <span className="text-yellow-500">Отправлен</span>;
      break;
    case "Принят":
      stats = <span className="text-green-700">Принят</span>;
      break;
    case "Отклонен":
    case "Отменен":
      stats = <span className="text-red-700">{status}</span>;
      break;
    default:
      stats = <span className="text-red-700">не доступен</span>;
      break;
  }
  return stats;
}