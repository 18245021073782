import { Routes, Route } from "react-router-dom";
import { BottomMenu } from "./components/bottom-menu";
import { ToastContainer } from 'react-toastify';
import { Layout } from "./components/layout";
import { Catalog } from "./pages/catalog";
import { Dashboard } from "./pages/dashboard";
import { GoodsInfo } from "./pages/goods-info";
import { Product } from "./pages/product-list";
import { Comments } from "./pages/goods-info/comments";
import { Detail } from "./pages/goods-info/detail";
import { Profile } from "./pages/profile";
import { ProfileInfo } from "./pages/profile/info";
import { About } from "./pages/about";
import { Faq } from "./pages/profile/faq";
import { Cart } from "./pages/cart";
import { Order } from "./pages/order";
import { Bonus } from "./pages/profile/bonus";
import { Rating } from "./pages/profile/rating";
import { Sellers } from "./pages/sellers";
import { SellerInfo } from "./pages/sellers/info";
import { Favorites } from "./pages/profile/favorites";
import { OrderHistory } from "./pages/profile/order/history";
// import { OrderShow } from "./pages/profile/order/show";
import { SellerReviews } from "./pages/sellers/reviews";
import { CatalogBrands } from "./pages/catalog/brands";
import { CatalogTree } from "./pages/catalog/tree";
import { SellerCatalogTree } from "./pages/sellers/tree";
import { SellerProductList } from "./pages/sellers/products";
import { SellerBrands } from "./pages/sellers/brands";
import { OrderEdit } from "./pages/profile/order/edit";

const App = (props) => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/catalog/brands" element={<CatalogBrands />} />
        <Route path="/catalog/tree" element={<CatalogTree />} />
        <Route path="/goods-info/:id" element={<GoodsInfo />} />
        <Route path="/product-list" element={<Product />} />
        <Route path="/comments/:id" element={<Comments />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/order/history" element={<OrderHistory />} />
        {/* <Route path="/profile/order/show/:id" element={<OrderShow />} /> */}
        <Route path="/profile/order/edit/:id" element={<OrderEdit />} />
        <Route path="/profile-info" element={<ProfileInfo />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/bonus" element={<Bonus />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/sellers" element={<Sellers />} />
        <Route path="/sellers/info/:id" element={<SellerInfo />} />
        <Route path="/sellers/reviews/:id" element={<SellerReviews />} />
        <Route path="/sellers/tree" element={<SellerCatalogTree />} />
        <Route path="/sellers/product-list" element={<SellerProductList />} />
        <Route path="/sellers/brands" element={<SellerBrands />} />
        <Route path="/favorites" element={<Favorites />} />
      </Routes>
      <BottomMenu />
      <ToastContainer theme="colored" autoClose={1500} />
    </Layout>
  );
};

export default App;
