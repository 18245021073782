import { useSearchParams } from "react-router-dom";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { PageContainer } from "../../components/page-container";
import { TreeCategoryQuery } from "../../components/categories/tree-query";
import { CatalogItem } from "../../components/catalog-item";

export function CatalogTree() {
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("parentCategoryId");

  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        <div className="flex space-x-2">
          <CatalogItem
            to="/product-list"
            icon="cube_outline"
            text="Все товары"
          />
          <CatalogItem to="/catalog/brands" icon="tag_outline" text="Бренды" />
          <CatalogItem
            to="/product-list?discount=1"
            icon="sale_outline"
            text="Акции"
          />
          <CatalogItem
            to="/product-list?popular=1"
            icon="thumb_up_outline"
            text="Популярные"
          />
        </div>
        <TreeCategoryQuery parentId={parentId} />
      </PageContainer>
    </>
  );
}
