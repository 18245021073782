import { forwardRef, useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { useLoadBonus } from "../../shared/hooks";
import { getImageUrl} from "../../shared/funcs"
import { IconCalendar, IconDollar } from "../../components/icons/icons";

export function Bonus() {
  const [pageNumber, setPageNumber] = useState(0);
  const { bonuses, hasMore, loading, error } = useLoadBonus(
    pageNumber
  );

  const observer = useRef();
  const lastBonusRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <SimpleTopNav title="Бонусы" />
      <PageContainer>
        {bonuses.map((order, index) => {
          if (bonuses.length === index + 1) {
            return (
              <BonusItem ref={lastBonusRef} key={order.id} item={order} />
            );
          } else {
            return <BonusItem key={order.id} item={order} />;
          }
        })}
        <div>{loading && 'Загрузка...'}</div>
        <div>{error && 'Ошибка загрузки'}</div>
      </PageContainer>
    </>
  );
}

const BonusItem = forwardRef(({item}, ref) => {
  return(
    <>
    <Link to={`/profile/order/edit/${item.documentPK.documentId}`}>
      
      <div ref={ref} className="card bg-base-100 card-compact mb-2 shadow">
          <div className="card-body info-card">
            <div className="flex justify-between items-end">
              <div className="avatar w-full">
                <div className="w-14 h-14 rounded-full mr-4">
                  <img src={getImageUrl(item.partner.imageUrl, 1)} alt={item.partner?.name} />
                </div>
                <div className="!aspect-auto">
                  <div className="text-gray-400 text-xs">№ {item.documentPK?.documentId}</div>
                  <h3 className="text-xl">{item.partner?.name}</h3>
                  <div className="text-gray-400 mt-1 flex items-center text-xs">
                    <div className="text-orange-500 mr-1">
                      <IconCalendar />
                    </div>
                    {item.createdAt}
                  </div>
                </div>
              </div>
              <div className="text-green-400 flex items-center">
                <IconDollar />
                <p className="w-max font-bold">{item.bonusAmount || 0} c</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
})

