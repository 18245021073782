import { observer } from "mobx-react-lite";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  // orderById,
  orderChangeStatus,
  orderUpdate,
  paymentAdd,
  oneTimePay,
} from "../../../api/order";
import { Icon } from "../../../components/icon";
import { IconMinus, IconPlus } from "../../../components/icons/icons";
import { PageContainer } from "../../../components/page-container";
import { OrderStatus } from "../../../components/profile/order-status";
import { SimpleTopNav } from "../../../components/simple-top-nav";
import { dateFormat, getImageUrl, isEditable } from "../../../shared/funcs";
import { useLoadContractorProducts } from "../../../shared/hooks";
import { useMst } from "../../../store";
import Sheet from "react-modal-sheet";
import orderStore from "../../../store/order";
import { cloneDeep } from "lodash";
import { errorToast, successToast } from "../../../shared/toast";
import { SendComment } from "../../../components/send-comment";
import { useOrderById } from "../../../shared/query-hooks";
import { useQueryClient } from "react-query";

export const OrderEdit = observer(() => {
  const queryClient = useQueryClient();
  const { user } = useMst();
  const { id } = useParams();
  // const [order, setOrder] = useState();
  const [isOpen, setOpen] = useState(false);
  const [isOpenOrder, setOpenOrder] = useState(false);
  const navigate = useNavigate();

  const { data: order } = useOrderById(id);

  useEffect(() => {
    if (order?.id) {
      orderStore.setProductList(order.goodsList);
    }
  }, [order]);

  const rightBtn = () => {
    return (
      <div
        className="p-2 bg-white w-full text-center border rounded-sm text-sm text-orange-500 active:bg-orange-50"
        onClick={() => updateOrder()}
      >
        Сохранить
      </div>
    );
  };

  /**
   * @param {boolean} send - параметр отправки заказа продавцу
   * @param {boolean} goBack - параметр для редиректа на предыдущую страницу
   */
  const updateOrder = async (send = false, goBack = false) => {
    try {
      const tmpOrder = {
        ...order,
        goodsList: orderStore.productList,
        totalAmount: orderStore.getTotalAmount(),
        status: send ? "Отправлен" : order.status,
      };
      const data = processFormBackend(tmpOrder, user.profile);
      await orderUpdate(data.id, data);
      successToast("Заказ обновлен");
      queryClient.invalidateQueries(["order", id]);
      if (goBack) {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      errorToast(error);
    }
  };

  const cancelOrder = async () => {
    try {
      await orderChangeStatus(id, "Отменен");
      successToast("Заказ отменён");
      queryClient.invalidateQueries(["order", id]);
      navigate(-1);
    } catch (error) {
      errorToast(error);
    }
  };

  const createPayment = async () => {
    const data = {
      status: "Новый",
      supplier: order.supplier.id,
      outletId: order.outlet.id,
      supplierDepartmentId: order.supplierDepartment?.id,
      outletDepartmentId: order.outletDepartment?.id,
      totalAmount: orderStore.getTotalAmount(),
    };
    try {
      return await paymentAdd(data);
    } catch (error) {
      console.error(error);
    }
  };

  const payOrder = async () => {
    try {
      await updateOrder(true, false);
      const payment = await createPayment();
      const responseUrl = await oneTimePay({
        externalId: payment.data.id.toString(),
        amount: orderStore.getTotalAmount(),
      });
      window.location.replace(`${responseUrl.data.data.token}`);
    } catch (error) {
      navigate(-1);
      errorToast(error);
    }
  };

  return (
    <>
      <SimpleTopNav title="Заказ" />
      <PageContainer>
        {order && (
          <>
            {(order.status === "Новый" || order.status === "Отправлен") &&
              isEditable(order.receiptDate) && (
                <div className="w-full flex justify-center mb-4">
                  {rightBtn()}
                </div>
              )}
            <div className="bg-white border shadow-md p-2 mb-4 rounded-md relative">
              <div className="font-semibold text-lg">#{order.id} {order.supplier.name}</div>
              <OrderRow>
                <div className="text-sm text-gray-400">Дата создания</div>
                <div className="text-sm">{dateFormat(order.createdAt)}</div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400">Дата доставки</div>
                <div className="text-sm">{dateFormat(order.receiptDate)}</div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400 ">Адрес продавца</div>
                <div className="text-sm text-right">
                  {order.supplier?.address
                    ? order.supplier?.address.description
                    : "----"}
                </div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400 ">Адрес доставки</div>
                <div className="text-sm text-right">
                  {order.address
                    ? order.address.description
                    : "----"}
                </div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400 ">Примечание</div>
                <div className="text-sm text-right">{order.note}</div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400 ">Итого</div>
                <div className="text-lg text-right font-bold">
                  {orderStore.getTotalAmount()} с
                </div>
              </OrderRow>
              <OrderRow>
                <div className="text-sm text-gray-400 ">Статус</div>
                <div className="text-sm text-right">
                  <OrderStatus status={order.status} />
                </div>
              </OrderRow>
            </div>
            <div className="pb-20">
              {orderStore.productList.map((good) => (
                <OrderGood
                  key={good.goods.id}
                  g={good}
                  seller={order.supplier}
                  isEditable={
                    (order.status === "Новый" ||
                      order.status === "Отправлен") &&
                    isEditable(order.receiptDate)
                  }
                />
              ))}
            </div>
          </>
        )}
      </PageContainer>

      {order && (
        <>
          <div className="fixed bottom-20 w-full  flex justify-center space-x-2 flex-row">
            {!order.isRated && order.status === "Принят" && (
              <SendComment entityId={order.supplier.id} seller />
            )}
            {order.status === "Новый" && isEditable(order.receiptDate) && (
              <>
                <Button onClick={() => setOpenOrder(true)}>Заказать</Button>
                <Button onClick={() => cancelOrder()}>Отменить заказ</Button>
              </>
            )}

            {(order.status === "Новый" || order.status === "Отправлен") &&
              isEditable(order.receiptDate) && (
                <Button onClick={() => setOpen(true)}>
                  <Icon
                    name="package_add"
                    className="h-8 w-8 fill-orange-500"
                  />
                </Button>
              )}
          </div>
          <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            snapPoints={[0.8, 0]}
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <ProductsModal seller={order.supplier} />
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>

          <Sheet
            isOpen={isOpenOrder}
            onClose={() => setOpenOrder(false)}
            snapPoints={[0.15, 0]}
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <div className="w-full flex justify-center space-x-2 flex-row">
                  <Button onClick={() => updateOrder(true, true)}>
                    Оплатить наличными
                  </Button>
                  <Button onClick={() => payOrder()}>Оплатить онлайн</Button>
                </div>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </>
      )}
    </>
  );
});

function OrderRow({ children }) {
  return (
    <div className="flex flex-row w-full justify-between items-center mb-1">
      {children}
    </div>
  );
}

function OrderGood({ g, seller, isEditable }) {
  return (
    <div className="bg-white flex justify-between items-center border rounded-md shadow-md p-2 mb-2">
      <div className="flex items-center">
        <div>
          <img
            src={getImageUrl(g.goods.imageUrl, 1)}
            className="h-auto w-14 max-w-none"
            alt={g.goods.name}
          />
        </div>
        <div className="h-full ml-3">
          <div className="font-bold text-sm">{g.goods?.name}</div>
          <div className="text-xs text-gray-400">{g.goods?.category?.name}</div>
          <div className="text-sm font-bold">{g.price} c</div>
        </div>
      </div>
      {isEditable && (
        <div>
          <ProductCounter item={g} seller={seller} />
        </div>
      )}
    </div>
  );
}

function ProductsModal({ seller }) {
  const { user } = useMst();
  const [pageNumber, setPageNumber] = useState(0);
  const { products, hasMore, loading, error } = useLoadContractorProducts(
    seller.id,
    null,
    null,
    null,
    null,
    user.profile?.contractor?.id,
    pageNumber
  );
  const observer = useRef();
  const lastProductRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <div className="py-2 h-64 flex flex-wrap">
        {products.map((item, index) => {
          if (products.length === index + 1) {
            return (
              <ProductItem
                ref={lastProductRef}
                key={item.id}
                item={item}
                seller={seller}
              />
            );
          } else {
            return <ProductItem key={item.id} item={item} seller={seller} />;
          }
        })}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка"}</div>
      </div>
    </>
  );
}

const ProductItem = forwardRef(({ item, seller }, ref) => {
  return (
    <div ref={ref} className="w-6/12 min-h-[260px] p-[5px]">
      <div className="relative w-full h-full bg-white shadow-md rounded-md border overflow-hidden">
        {item.isPopular && (
          <div className="bg-orange-500 absolute rounded-tl-md text-white text-xs p-1 flex flex-row items-center">
            <Icon name="decagram" className="h-3 w-3 fill-white mr-1" />
            Хит!
          </div>
        )}
        <img
          className="my-[20px] mx-auto h-[95px]"
          src={getImageUrl(item.goods?.imageUrl, 1)}
          alt={item.goods?.name}
        />
        <p className="text-xs text-center font-bold mb-2 h-[46px] mx-[3px] text-ellipsis overflow-hidden">
          {item.goods?.name}
        </p>

        <div className="px-1 mt-4 mb-1 flex items-center justify-between">
          <p className="font-bold text-sm price-color bottom-4 left-0 w-full px-4 text-center">
            {item.price || 0} c
          </p>
          <ProductCounter item={item} seller={seller} />
        </div>
      </div>
    </div>
  );
});
const ProductCounter = observer(({ item, seller }) => {
  const handlePlus = () => {
    const cartItem = orderStore.checkIn(item.goods.id);
    if (cartItem) {
      // check item in cart
      let cnt = orderStore.getItemCount(item.goods.id);
      orderStore.updateCount(item.goods.id, cnt + 1);
    } else {
      // new item
      let obj = {
        amount: item.price,
        characteristic: item.characteristic || null,
        count: 1,
        goods: item.goods,
        price: item.price,
        rowIndex: null,
      };
      orderStore.addProduct(obj);
    }
  };
  const handleMinus = () => {
    const cartItem = orderStore.checkIn(item.goods.id);
    if (cartItem.count === 1) {
      // delete item
      orderStore.deleteItem(item.goods.id);
    } else {
      // update item
      let cnt = orderStore.getItemCount(item.goods.id);
      orderStore.updateCount(item.goods.id, cnt - 1);
    }
  };
  return (
    <div className="text-sm py-2">
      <div className="flex flex-row space-x-2 w-full items-center justify-end">
        <button
          className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
          onClick={(e) => {
            e.stopPropagation();
            handleMinus();
          }}
        >
          <IconMinus />
        </button>
        <p> {orderStore.getItemCount(item.goods.id)} </p>
        <button
          className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
          onClick={(e) => {
            e.stopPropagation();
            handlePlus();
          }}
        >
          <IconPlus />
        </button>
      </div>
    </div>
  );
});

const Button = ({ onClick, children }) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="p-2 shadow-md mt-3 mb-2 border rounded-md flex items-center bg-white text-center text-orange-600 active:bg-orange-50"
    >
      {children}
    </div>
  );
};

function processFormBackend(data, profile) {
  let object = cloneDeep(data);

  object.outletId = profile.contractor.id;
  object.outletDepartmentId = profile.department?.id;
  object.supplier = object.supplier.id;
  object.supplierDepartmentId = object.supplierDepartment.id;

  // object.totalAmount = object.totalAmountExtra
  //   ? object.totalAmount + object.totalAmountExtra
  //   : object.totalAmount;
  // object.totalWeight = object.totalWeightExtra
  //   ? object.totalWeight + object.totalWeightExtra
  //   : object.totalWeight;

  if (object.note === "") {
    object.note = null;
  }
  delete object.outlet;
  delete object.outletDepartment;
  delete object.supplierDepartment;
  delete object.totalAmountExtra;
  delete object.totalWeightExtra;

  object.goodsList = processGoodsList(object.goodsList);

  return object;
}
function processGoodsList(goodsList) {
  console.log(goodsList);
  if (goodsList && goodsList.length > 0) {
    goodsList = goodsList.map((item, index) => {
      item.goodsId = item.goods.id;
      item.characteristicId = item.goods.characteristic
        ? item.goods.characteristic.id
        : null;
      item.unitId = item.goods.unit ? item.goods.unit.id : null;
      item.rowIndex = index + 1;
      delete item.goods;
      delete item.unit;
      if (item.characteristic) {
        delete item.characteristic;
      }
      return item;
    });
    return goodsList;
  }
  return null;
}
