import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useMst } from "../../store";

export function BottomMenu() {
  return (
    <div className="fixed bottom-0 left-0 bg-base-100 rounded-t-2xl w-full h-16 bottom-tabs pt-3">
      <div className="flex flex-row justify-around p">
        <BottomMenuItem to="/" name="main" title="Главная" />
        <BottomMenuItem to="/catalog" name="catalog" title="Каталог" />

        <BottomMenuItem to="/cart" name="cart" title="Корзина" />
        <BottomMenuItem to="/sellers" name="sellers" title="Продавцы" />
        <BottomMenuItem to="/profile" name="profile" title="Профиль" />
      </div>
    </div>
  );
}
export const BottomMenuItem = observer(({ name, title, to }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved?.pathname, end: true });
  const { cart } = useMst();

  return (
    <Link to={to ?? "/"}>
      <div className="flex flex-col items-center relative">
        {name === "cart" && cart.productList.length > 0 && (
          <span className="badge bg-red-400 text-white border-0 absolute -right-2 -top-3">
            {cart.productList.length}
          </span>
        )}
        <svg
          className={`icon ${match ? "fill-orange-500" : null}`}
          fill="#969696"
        >
          <use
            xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#${name}`}
          ></use>
        </svg>
        <p className={`text-xs mt-2 ${match && "text-orange-500"}`}>{title}</p>
      </div>
    </Link>
  );
});
BottomMenuItem.defaultProps = {
  to: null,
};
