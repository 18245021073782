import { makeAutoObservable } from "mobx";
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';

function traslateCategoryType(categoryType) {
  let ct;
  switch (categoryType) {
    case "CBM":
      ct = "Стройматериалы";
      break;
    case "GOODS":
    default:
      ct = "Товары";
  }
  return ct;
}
function translateContractorType(contractorType) {
  let ct;
  switch (contractorType) {
    case "SHOP":
      ct = "Магазин";
      break;
    case "CUSTOMER":
    default:
      ct = "Клиент";
  }
  return ct;
}
class AppStore {
  loading = false;
  contractorType = null;
  categoryType = null;
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'AppStore', properties: ['contractorType', 'categoryType'], storage: window.localStorage });
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setData(categoryType, contractorType) {
    this.categoryType = traslateCategoryType(categoryType);
    this.contractorType = translateContractorType(contractorType);
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}
export default AppStore;
