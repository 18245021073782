import React from "react";
import { useNavigate } from "react-router-dom";
import { useMst } from "../store";
import { Icon } from "./icon";
import { IconArrowLeft } from "./icons/icon-arrow-left";

export function SimpleTopNav({ title }) {
  const navigate = useNavigate();
  return (
    <div className="fixed w-full z-20 bg-base-100 overflow-y-hidden top-0 inset-x-0">
      <div className="flex items-center justify-between w-full h-[54px]">
        <button className="px-4 w-14" onClick={() => navigate(-1)}>
          <IconArrowLeft />
        </button>
        <div className="w-full px-3 text-bold text-md font-semibold text-center truncate">
          {title}
        </div>
        {/* {right && right()} */}
        <div className="w-14">
          <CloseBtn />
        </div>
      </div>
    </div>
  );
}

SimpleTopNav.defaultProps = {
  title: "",
};

const CloseBtn = () => {
  const { user } = useMst();
  return (
    <a
      href="native://goBack"
      onClick={() => user.exit()}
      className="bg-slate-300 w-12 flex items-center justify-center rounded-md mr-3"
    >
      <Icon name="close" className="h-8 w-8 fill-white" />
    </a>
  );
};
