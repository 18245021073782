import clsx from "clsx";

export function Icon({ className, fill, name }) {
  return (
    <svg className={clsx(className, fill)}>
      <use
        href={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#${name}`}
      ></use>
    </svg>
  );
}
Icon.defaultProps = {
  className: "h-6 w-6",
};
