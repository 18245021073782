import dayjs from "dayjs";
/**
 * Возвращает полный линк на картинку
 * @param {string} url
 */
export function getImageUrl(url, size) {
  try {
    if (url.match(/^http/)) {
      return encodeURI(url);
    }
    let getUrl = url.replace(/download\//, "");
    getUrl = size ? `${getUrl}/size/${size}` : getUrl;
    return encodeURI(`${process.env.REACT_APP_BACKEND_URL}${getUrl}`);
  } catch (error) {
    return "/images/no-photo.png";
  }
}

/**
 * https://day.js.org/docs/en/display/format
 * @param {str} date
 * @param {str} format
 * @returns {str}
 */
export function dateFormat(str, format = "DD.MM.YYYY HH:mm") {
  try {
    if (str === null) return "";
    return dayjs(str).format(format);
  } catch (error) {
    console.error(error)
    return "";
  }
}

export function checkUser(profile) {
  try {
    return profile.id ? true : false;
  } catch (error) {
    return false;
  }
}

export function isEditable(receiptDate) {
  try {
    const receipt = new Date(receiptDate);
    const today = new Date();
    return receipt.getTime() > today.getTime();
  } catch (error) {
    return false;
  }
}
