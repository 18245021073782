import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { goodCategories } from "../../api/product";
import { useMst } from "../../store";

// import {
//   SellerBackground,
//   SellerCard,
// } from "../../components/sellers/seller-info";
import useSeller from "./hooks/useSeller";
import { ZeroCategory } from "../../components/categories/zero";
import { TreeCategory } from "../../components/categories/tree";
import { CatalogItem } from "../../components/catalog-item";

export function SellerInfo() {
  const [categories, setCategories] = useState([]);
  const { id } = useParams();

  const { data: seller } = useSeller(id);

  const { app } = useMst();

  // категории нулевого уровня
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await goodCategories({
          paging: { page: 0, limit: 80 },
          filters: {
            showParent: true,
            showActive: true,
            categoryType: app.categoryType,
            contractorId: id,
          },
        });
        setCategories(response.data.result);
      } catch (error) {
        // do nothing
      }
    };
    fetchCategories();
  }, [app.categoryType, id]);

  if (!seller) {
    return null;
  }
  return (
    <>
      <SimpleTopNav title={seller.name}/>
      {/* {seller && <SellerBackground seller={seller} />} */}
      <PageContainer>
        {/* {seller && <SellerCard seller={seller} />} */}
        <div className="flex space-x-2 justify-center">
          <CatalogItem to={`/sellers/product-list?contractorId=${seller.id}`} icon="cube_outline" text="Все товары" />
          <CatalogItem to={`/sellers/brands?contractorId=${seller.id}`} icon="tag_outline" text="Бренды" />
          <CatalogItem to={`/sellers/product-list?discount=1&contractorId=${seller.id}`} icon="sale_outline" text="Акции" />
          {/* <CatalogItem to={`/sellers/product-list?popular=1&contractorId=${seller.id}`} icon="thumb_up_outline" text="Популярные" /> */}
        </div>

        {categories.length > 1 &&
          categories.map((item) => (
            <ZeroCategory key={item.id} item={item} contractorId={id} pathname="/sellers/tree" />
          ))}

        {categories.length === 1 && (
          <TreeCategory
            parentId={categories[0].id}
            contractorId={id}
            pathname="/sellers/product-list"
          />
        )}
      </PageContainer>
    </>
  );
}
