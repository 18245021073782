import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { goodRecommendations } from "../../api/product";
import { getImageUrl } from "../../shared/funcs";

export function Recomendations({ goodId }) {
  const navigate = useNavigate();
  const [recs, setRecs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await goodRecommendations(goodId);
        setRecs(response.data);
      } catch (error) {
        // nothing
      }
    };
    fetchData();
  }, [goodId]);

  if (recs.length === 0) return null;

  <div className="mt-4">
    <p className="text-xl font-semibold">Рекомендуемые товары</p>
    <div className="carousel space-x-4 p-4">
      {recs.map((item, index) => (
        <div key={index} className="carousel-item">
          <div
            className="shadow-lg bg-base-100 w-40 flex flex-col rounded-box border"
            onClick={() => navigate(`/goods-info/${item.id}`)}
          >
            <div className="p-4  flex justify-center">
              <img
                className="rounded max-h-28"
                src={getImageUrl(item.imageUrl, 1)}
                alt={item.name}
              />
            </div>
            <p className="mt-4 text-center h-12 text-xs text-ellipsis overflow-hidden">
              {item.name}
            </p>

            <div className="px-4 mt-4 mb-1 ">
              <p className="font-bold text-xs price-color">
                {item.priceRange} c
              </p>
            </div>
            {/* <div className="px-4 mb-1 flex justify-end">
          <div className="flex border-2 rounded-xl p-1">
            <Icon name="cart" className="h-4 w-4 fill-orange-500" />
          </div>
        </div> */}
          </div>
        </div>
      ))}
    </div>
  </div>;
}
