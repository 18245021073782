/**
 * Рейтинг и комментарий для товара или продавца
 */
import { useState } from "react";
import { Icon } from "./icon";
import Sheet from "react-modal-sheet";
import clsx from "clsx";
import { errorToast, successToast, warnToast } from "../shared/toast";
import { goodsReview } from "../api/product";
import { contractorReview } from "../api/contractor";
import { useQueryClient } from "react-query";

export const SendComment = ({ entityId, seller }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRate] = useState(0);
  const [message, setText] = useState();
  const toggle = () => setIsOpen((prev) => !prev);

  const sendReview = async () => {
    try {
      if (rating !== 0) {
        if (seller) {
          await contractorReview({ contractorId: entityId, rating, message });
          queryClient.invalidateQueries("order");
        } else {
          await goodsReview({ goodsId: entityId, rating, message });
          queryClient.invalidateQueries("good");
        }
        setRate(0);
        setText(undefined);
        toggle();
        successToast("Спасибо за ваш отзыв");
      } else {
        warnToast("Оставьте оценку!");
      }
    } catch (error) {
      errorToast(error);
    }
  };
  return (
    <>
      <div className={clsx({ "fixed w-full px-4 bottom-[70px]": !seller })}>
        <div
          role="button"
          className={clsx(
            { "p-2 bg-orange-500 active:bg-orange-400 rounded-xl": !seller },
            {
              "p-2 shadow-md mt-3 mb-2 border rounded-md flex items-center bg-white text-center text-orange-600 active:bg-orange-50 h-[50px]":
                seller,
            }
          )}
          onClick={toggle}
        >
          {seller ? (
            "Отзыв"
          ) : (
            <div className="text-center text-white">Оставить отзыв</div>
          )}
        </div>
      </div>
      <Sheet isOpen={isOpen} onClose={toggle} snapPoints={[0.5, 0]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="flex justify-around md:w-1/2">
              {[1, 2, 3, 4, 5].map((item) => (
                <div key={item} onClick={() => setRate(item)}>
                  <Icon
                    name="star"
                    className={clsx(
                      "h-16",
                      "w-16",
                      item <= rating ? "fill-orange-500" : "fill-gray-300"
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="px-4 mt-8">
              <textarea
                type="text"
                placeholder="Комментарий"
                className="input block w-full h-20 p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-orange-500 focus:border-orange-500 focus:ring-2 focus:outline-none"
                onChange={(e) => setText(e.currentTarget.value)}
              ></textarea>
              <div
                role="button"
                className="p-2 shadow-md mt-3 mb-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                onClick={() => sendReview()}
              >
                Отправить
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

SendComment.defaultProps = {
  seller: false,
};
