/**
 * Список категорий нулевого уровня
 */
import { createSearchParams, useNavigate } from "react-router-dom";
import { Icon } from "../icon";
import { getImageUrl } from "../../shared/funcs";

export const ZeroCategory = ({ item, pathname, contractorId }) => {
  const navigate = useNavigate();
  const toCategorylist = (parentId) => {
    navigate({
      pathname,
      search: createSearchParams({
        parentCategoryId: parentId,
        contractorId,
      }).toString(),
    });
  };
  return (
    <div
      className="my-2 p-3 rounded-md bg-white border flex flex-row justify-between items-center"
      onClick={() => toCategorylist(item.id)}
    >
      <div className="flex flex-row items-center space-x-1">
        <img src={getImageUrl(item.imageUrl, 1)} className="h-12 w-12" alt={item.name} />
        <div className="text-md font-bold">{item.name}</div>
      </div>
      <Icon name="chevron_right" />
    </div>
  );
};

ZeroCategory.defaultProps = {
  pathname: "/catalog/tree",
  contractorId: undefined,
};
