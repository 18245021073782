import { createContext, useContext } from "react";
import AppStore from './app';
import UserStore from './user';
import CartStore from "./cart";



class RootStore {
  constructor() {
    this.app = new AppStore();
    this.user = new UserStore(this);
    this.cart = new CartStore();
  }
}

const RootStoreContext = createContext(null);
export function RootStoreProvider({ children, store }) {
  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>
}

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export default RootStore;