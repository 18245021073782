import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

export function Slider() {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      // slides: {
      //   perView: 1,
      // },
      breakpoints: {
        "(min-width: 400px)": {
          slides: { perView: 1 },
        },

        "(min-width: 500px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 1000px)": {
          slides: { perView: 3, spacing: 10 },
        },
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  return (
    <div ref={sliderRef} className="keen-slider mt-4">
      <div className="h-28">
        <img
            src={process.env.PUBLIC_URL + "/images/promo1.webp"}
            className="keen-slider__slide h-28 w-auto object-cover bg-transparent rounded-xl"
            alt="img"
          />
      </div>
      <div className="h-28">
        <img
            src={process.env.PUBLIC_URL + "/images/promo2.webp"}
            className="keen-slider__slide h-28 w-auto object-cover bg-transparent rounded-xl"
            alt="img"
          />
      </div>
      <div className="h-28">
        <img
          src={process.env.PUBLIC_URL + "/images/promo3.webp"}
          className="keen-slider__slide h-28 w-auto object-cover bg-transparent rounded-xl"
          alt="img"
        />
      </div>
    </div>
  );
}
