import { client } from "./client";
const ORDERS_API = "/api/orders";
const ADDRESS_API = "/api/address";
const BONUS_API = "/api/bonus-transaction";
const CONTR_REVIEW = '/api/contractor-review/contractor/';
const PAYMENT_API = '/api/payments';

/**
 * Create orders
 * @param {object} params - request data
 * @returns Promise
 */
export function ordersAdd(params) {
  return client.post(`${ORDERS_API}`, params);
}

/**
 * Search orders
 * @param {object} params - request data
 * @returns Promise
 */
export function ordersSearch(params) {
  return client.post(`${ORDERS_API}/doSearch`, params);
}

export function addressAdd(params) {
  return client.post(`${ADDRESS_API}`, params);
}

export function orderById(id) {
  return client.get(`${ORDERS_API}/${id}`);
}

export function bonusSearch(params) {
  return client.post(`${BONUS_API}/doSearch`, params);
}

export function contractorReviev(id,params) {
  return client.post(`${CONTR_REVIEW}/contractor/${id}/dosearch`)
}

export function orderUpdate(id, params) {
  return client.put(`${ORDERS_API}/${id}`, params)
}

export function orderDelete(id, params) {
  return client.delete(`${ORDERS_API}/${id}`)
}

export function orderChangeStatus(id, status) {
  return client.post(`${ORDERS_API}/${id}/change-status`, {status})
}


export function paymentAdd(params) {
  return client.post(`${PAYMENT_API}`, params);
}

export function oneTimePay(params) {
  return client.post(`/api/namba/payment/one-time`, params);
};
