import { observer } from "mobx-react-lite";
import { useState } from "react";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { useMst } from "../../store";
import DatePicker from "react-date-picker";
import { Link, useSearchParams } from "react-router-dom";
import { errorToast } from "../../shared/toast";
import isEmpty from "lodash/isEmpty";
import { IconCheckLarge } from "../../components/icons/icons";
import { addressAdd, oneTimePay, ordersAdd, paymentAdd } from "../../api/order";
import { Map } from "../../components/order/map";
import dayjs from "dayjs";
import Sheet from "react-modal-sheet";

export const Order = observer(() => {
  const { cart, user } = useMst();

  const totals = cart.totalWeightAmountByContractor();

  const [searchParams] = useSearchParams();

  /** адрес доставки */
  const [description, setDescription] = useState("");
  /** дата доставки */
  const [delDate, setDelDate] = useState(new Date());
  /** комментарий к заказу */
  const [note, setNote] = useState("");
  /** флаг, чтобы показать что заказ отправлен */
  const [sended, setSended] = useState(false);
  /** позиция */
  const [position, setPosition] = useState();
  /** выбор способа оплаты */
  const [isOpenPay, setIsOpenPay] = useState(false);
  const togglePay = () => setIsOpenPay(prev => !prev);

  const orderNow = () => {
    if (delivery && isEmpty(description)) {
      errorToast("Введите адрес доставки");
      return;
    }
    togglePay();
  }

  const delivery = searchParams.get("delivery") === "Доставка" ? true : false;

  const sendNow = async () => {
    if (delivery && isEmpty(description)) {
      errorToast("Введите адрес доставки");
      return;
    }
    togglePay();
    await generateOrder("Отправлен");
    
  };

  const sendDeffered = () => {
    if (delivery && isEmpty(description)) {
      errorToast("Введите адрес доставки");
      return;
    }
    generateOrder("Новый");
  };

  const generateOrder = async (status) => {
    try {
      let goodsList = totals.products.map((item) => item.asJSON);
      let addressId;
      if (delivery) {
        const response = await addressAdd({
          description: description.length !== 0 ? description : null,
          lat: position && position.length !== 0 ? position[0] : null,
          lng: position && position.length !== 0 ? position[1] : null,
        });
        addressId = response.data.id;
      }

      let order = {
        status,
        totalAmount: totals.totalA,
        totalWeithg: totals.totalW,
        deliveryType: delivery ? "Доставка" : "Самовывоз",
        addressId,
        createdDate: new Date(),
        receiptDate: dayjs(delDate).add(1, 'hour'),
        supplier: cart.selectContractor?.id,
        supplierDepartmentId: cart.selectContractor?.department,
        outletId: user.profile?.contractor?.id,
        outletDepartmentId: user.profile?.contractor?.department?.id,
        goodsList: [],
        note: note || null,
      };
      goodsList.forEach((good) => {
        order.goodsList.push({
          goodsId: good.good.id,
          count: good.count,
          price: good.price,
          amount: +good.count * +good.price,
        });
      });

      await ordersAdd(order);
      cart.clearProducsByContractor();
      setSended(true);
    } catch (error) {
      errorToast(error);
    }
  };

  const createPayment = async (contractor) => {
    const data = {
      status: "Новый",
      supplier: contractor?.id,
      outletId: user.profile?.contractor?.id,
      supplierDepartmentId: contractor?.department,
      outletDepartmentId: user.profile?.contractor?.department?.id,
      totalAmount: totals.totalA,
    };
    try {
      return await paymentAdd(data);
    } catch (error) {
      console.error(error);
    }
  };

  const payOrder = async () => {
    try {
      if (delivery && isEmpty(description)) {
        errorToast("Введите адрес доставки");
        return;
      }
      togglePay();
      let tmpTotalsA = totals.totalA.toString();
      let tmpContractor = {...cart.selectContractor};
      await generateOrder("Отправлен");
      const payment = await createPayment(tmpContractor);
      const responseUrl = await oneTimePay({
        externalId: payment.data.id.toString(),
        amount: tmpTotalsA,
      });
      window.location.replace(`${responseUrl.data.data.token}`);
    } catch (error) {
      // navigate(-1);
      errorToast(error);
    }
  };

  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        {sended ? (
          <Sended cartList={cart.productList.length} />
        ) : (
          <>
            <div className="bg-white rounded-md p-2 border divide-y mb-2">
              <TotalRow>
                Общий вес <span>{totals.totalW} кг</span>
              </TotalRow>
              <TotalRow>
                Итого к оплате <span>{totals.totalA} c</span>
              </TotalRow>
            </div>
            {delivery ? (
              <div className="bg-white rounded-md p-2 border divide-y mb-2">
                <div className="text-md font-bold">Адрес доставки <span className="text-red-600">*</span></div>
                <div className="mt-2">
                  <textarea
                    className="border w-full h-24"
                    placeholder="Адрес доставки"
                    onChange={(e) => setDescription(e.currentTarget.value)}
                  ></textarea>
                </div>
                <div className="mt-2 py-4 flex flex-col">
                  <Map position={position} setPosition={setPosition} />
                  {position &&<span className="text-xs text-gray-400">* Адрес на карте указан</span>}
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-md p-2 border mb-2">
                <div className="text-md font-bold">Адрес: {cart.selectContractor.name}</div>
              
                <TotalRow>
                  {cart.selectContractor.address.description}
                </TotalRow>

              </div>
            )}

            <div className="bg-white rounded-md p-2 border mb-2">
              <div className="text-md font-bold">Данные доставки</div>
              <div className="mt-2 flex flex-col">
                <label htmlFor="del-date" className="text-sm font-bold">
                  Желаемый день доставки
                </label>
                <DatePicker
                  onChange={setDelDate}
                  value={delDate}
                  format="dd.MM.yyyy"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="del-comment" className="text-sm font-bold">
                  Комментарий к заказу
                </label>
                <textarea
                  className="border w-full h-24"
                  onChange={(e) => setNote(e.currentTarget.value)}
                ></textarea>
              </div>
            </div>

            <div className="bg-white rounded-md p-0 border mb-2">
              <div className="py-2 flex flex-row ">
                <div
                  className="flex items-center justify-center py-2 w-1/2 shadow-md mx-2 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                  onClick={() => orderNow()}
                >
                  Заказать сейчас
                </div>
                <div
                  className="flex items-center justify-center py-2 w-1/2 shadow-md mx-2 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                  onClick={() => sendDeffered()}
                >
                  Отложенный заказ
                </div>
              </div>
            </div>
          </>
        )}
         <SelectPayment sendNow={sendNow} payOrder={payOrder} isOpen={isOpenPay} toggle={togglePay} />
      </PageContainer>
     
    </>
  );
});

function TotalRow({ children }) {
  return <div className="flex flex-row justify-between my-1">{children}</div>;
}
/** показываем после отправки */
function Sended({ cartList }) {
  return (
    <div className="flex flex-col mt-16 items-center justify-center">
      <div className="text-green-500 text-center">
        <IconCheckLarge />
      </div>
      <div className="font-bold mt-6">Ваш заказ успешно оформлен</div>
      <div className="flex flex-col items-center mt-12 w-full px-2 bg-white border rounded-md">
        {cartList > 0 && (
          <Link
            to="/cart"
            className="p-4 w-1/2 shadow-md mx-3 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
          >
            В корзину
          </Link>
        )}
        <Link
          to="/"
          className="p-4 w-1/2 shadow-md mx-3 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
        >
          На главную
        </Link>
        <Link
          to="/profile/order/history"
          className="p-4 w-1/2 shadow-md mx-3 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
        >
          В заказы
        </Link>
      </div>
    </div>
  );
}

function SelectPayment({isOpen, toggle, sendNow, payOrder}) {
  return(
    <Sheet isOpen={isOpen} onClose={toggle} snapPoints={[0.2, 0]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="flex justify-around md:w-1/2">
            <div
                  className="p-2 flex items-center justify-center w-1/2 shadow-md mx-3 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                  onClick={() => payOrder()}
                >
                  Оплатить онлайн
                </div>
                <div
                  className="p-2 w-1/2 shadow-md mx-3 my-2 border rounded-md text-center text-orange-600 active:bg-orange-50"
                  onClick={() => sendNow()}
                >
                  Оплатить наличными
                </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
  )
}