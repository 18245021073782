import { useSearchParams } from "react-router-dom";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { PageContainer } from "../../components/page-container";
import useSeller from "./hooks/useSeller";
import {
  SellerBackground,
  SellerCard,
} from "../../components/sellers/seller-info";
import { TreeCategory } from "../../components/categories/tree";

export function SellerCatalogTree() {
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("parentCategoryId");
  const contractorId = searchParams.get("contractorId");
  const { data: seller } = useSeller(contractorId);

  return (
    <>
      <SimpleTopNav title={seller.name} />
      {seller && <SellerBackground seller={seller} />}
      <PageContainer>
        {seller && <SellerCard seller={seller} />}
        <TreeCategory
          parentId={parentId}
          contractorId={contractorId}
          pathname="/sellers/product-list"
        />
      </PageContainer>
    </>
  );
}
