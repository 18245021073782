import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { goodsFavorites } from "../../api/product";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getImageUrl } from "../../shared/funcs";
import { errorToast } from "../../shared/toast";

export function Favorites() {
  const [goods, setGoods] = useState([]);

  const navigate = useNavigate();
  const showProduct = (id) => {
    navigate(`/goods-info/${id}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await goodsFavorites();
        setGoods(response.data.result);
      } catch (error) {
        // do nothing
        errorToast("Не удалось загрузить список продуктов");
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <SimpleTopNav title="Избранное" />
      <PageContainer flex>
        {goods.map((item) => (
          <div
            className="w-6/12 h-[254px] p-[4px]"
            onClick={() => showProduct(item.id)}
            key={item.id}
          >
            <div className="relative w-full h-full bg-white border overflow-hidden">
              <img
                className="my-[20px] mx-auto h-[95px]"
                src={getImageUrl(item.imageUrl, 1)}
                alt={item.name}
              />
              <p className="text-xs text-center font-semibold mb-2 h-[45px] mx-[3px]">
                {item.name}
              </p>

              <div className="px-4 mt-4 mb-1 flex items-end justify-between">
                <p className="font-bold text-sm price-color">
                  {item.priceRange || 0} c
                </p>
              </div>
            </div>
          </div>
        ))}
      </PageContainer>
    </>
  );
}
