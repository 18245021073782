import React from "react";
import {
  IconClock,
  IconInfo,
  IconPencil,
  IconPhone,
  IconUser
} from "../../components/icons/icons";

export function ProfileInfo() {
  return (
    <>
      <div className="bg-cover bg-center bg-slate-100 rounded-b-xl shadow" style={{backgroundImage: 'url(https://api.lorem.space/image/shoes?w=400&h=225)'}}>
          <div className="bg-black/[0.4] rounded-b-xl">
            <div className="container items-start p-4 flex justify-between">
              <div className="flex">
                <div className="avatar">
                  <div className="w-16 h-16 rounded-full mr-4">
                    <img src="https://api.lorem.space/image/face?hash=92310" alt="аватар"/>
                  </div>
                </div>
                <div className="text-white">
                  <div className="flex items-center mb-2">
                    <div className="text-orange-500 mr-2">
                      <IconUser />
                    </div>
                    <p className="font-bold">Light store kg</p>
                  </div>
                  <div className="flex items-center mb-2">
                    <div className="text-orange-500 mr-2">
                      <IconInfo />
                    </div>
                    <p className="text-xs text-gray-300">description</p>
                  </div>
                  <div className="flex items-center mb-2">
                    <div className="text-orange-500 mr-2">
                      <IconClock />
                    </div>
                    <p>ПН - ПТ 9:00 - 18:00</p>
                  </div>
                  <div className="flex items-center">
                    <div className="text-orange-500 mr-2">
                      <IconPhone />
                    </div>
                    <p>+996855004400</p>
                  </div>
                </div>
              </div>
              <div className="text-orange-500">
                <IconPencil />
              </div>
            </div>
        </div>
      </div>
      <div className="container items-center p-2 pb-40">
        <div className="card bg-base-100 card-compact mb-2 shadow">
          <div className="card-body info-card">
            <p className="text-xl">Адрес</p>
            <div className="bg-base-200 p-2 rounded-lg text-gray-400">
              Рынок: Дордой
            </div>
            <div className="bg-base-200 p-2 rounded-lg text-gray-400">
              Ряд: 15
            </div>
            <div className="bg-base-200 p-2 rounded-lg text-gray-400">
              Контейнер: 15
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
