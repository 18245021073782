import { useParams } from "react-router-dom";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { forwardRef, useCallback, useRef, useState } from "react";
import { useLoadContractorReviews } from "../../shared/hooks";
import { Icon } from "../../components/icon";
import { dateFormat } from "../../shared/funcs";
import { RatingStars } from "../../components/rating-stars";

export function SellerReviews() {
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState(0);
  const {comments, hasMore, loading, error} = useLoadContractorReviews(id, pageNumber);

  const observer = useRef();
  const lastCommentsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  
  return (<>
    <SimpleTopNav title="Отзывы" />
    <PageContainer>
      {!loading && comments.length === 0 && (
        <div className="flex flex-col items-center mt-10">
          <Icon name="message" className="h-28 w-28 fill-gray-500" />
          <div className="font-semibold text-xl text-gray-400">
            Отзывов нет
          </div>
        </div>
      )}
      
      {comments.map((item, index) => {
        if (comments.length === index + 1) {
          return (
            <CommentItem ref={lastCommentsRef} key={item.id} item={item} />
          );
        } else {
          return <CommentItem key={item.id} item={item} />;
        }
      })}
      <div>{loading && "Загрузка..."}</div>
      <div>{error && "Ошибка"}</div>
    </PageContainer>
  </>)
 
}
const CommentItem = forwardRef(({ item }, ref) => {
  return (
    <div className="p-4 bg-white mb-2 border rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div>
            <div className="font-bold">{item.username}</div>
            <div className="text-xs text-gray-400">{dateFormat(item.createdAt, 'DD.MM.YYYY')}</div>
          </div>
        </div>
        <div className="font-bold text-xs">
          <RatingStars stars={item.rating}/>
        </div>
      </div>
      <div className="text-gray-500">
        {item.message}
      </div>
    </div>
  );
});