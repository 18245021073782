import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { getImageUrl } from "../../shared/funcs";
import { useMst } from "../../store";
import {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useEffect,
  Fragment,
} from "react";
import { useLoadBrands } from "../../shared/hooks";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { searchTradeMark } from "../../api/catalog";
import { CACHETIME, PER_PAGE } from "../../shared/constants";

export function CatalogBrands() {
  const { app } = useMst();
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const {
    status,
    data,
    error,
    fetchNextPage,
  } = useInfiniteQuery(
    "projects",
    async ({ pageParam = 0 }) => {
      const res = await searchTradeMark({
        paging: { page: pageParam, limit: PER_PAGE },
        filters: {
          categoryType: app.categoryType,
          showActive: true,
        },
      });
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const pageCount = Math.ceil(lastPage.totalCount / PER_PAGE);
        const next = lastPage.paging.page + 1;
        return next < pageCount ? next : undefined;
      },
      cacheTime: CACHETIME, 
      staleTime: Infinity,
      retry:false
    }
  );

  const onSelectBrand = (id) => {
    navigate({
      pathname: "/product-list",
      search: createSearchParams({
        brandId: id,
      }).toString(),
    });
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]); // eslint-disable-line

  return (
    <>
      <SimpleTopNav title="Бренды" />
      <PageContainer>
        {status === "loading" ? (
          <p>Загрузка...</p>
        ) : status === "error" ? (
          <span>Ошибка: {error.message}</span>
        ) : (
          <div ref={ref} className="flex flex-wrap">
            {data.pages.map((page, index) => (
              <Fragment key={index}>
                {page.result.map((brand) => (
                  <Brand key={brand.id} item={brand} onClick={() => onSelectBrand(brand.id)} />
                ))}
              </Fragment>
            ))}
          </div>
        )}
      </PageContainer>
    </>
  );
}
export function CatalogBrandsold() {
  const { app } = useMst();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const { brands, hasMore, loading, error } = useLoadBrands(
    app.categoryType,
    pageNumber
  );

  const observer = useRef();
  const lastBrandRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const onSelectBrand = (id) => {
    console.log(id);
    navigate({
      pathname: "/product-list",
      search: createSearchParams({
        brandId: id,
      }).toString(),
    });
  };

  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        <div className="flex flex-wrap">
          {brands.map((brand, index) => (
            <Brand
              ref={brands.length === index + 1 ? lastBrandRef : null}
              key={brand.id}
              item={brand}
              onClick={onSelectBrand}
            />
          ))}
        </div>
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка загрузки"}</div>
      </PageContainer>
    </>
  );
}

const Brand = forwardRef(({ item, onClick }, ref) => {
  return (
    <div
      ref={ref}
      className="w-4/12 p-[5px] h-[110px]"
      onClick={() => onClick(item.id)}
    >
      <div className="relative flex flex-col items-center w-full h-full p-2 bg-white rounded-xl shadow-md overflow-hidden">
        <img
          className="h-[60px] w-[60px] object-contain"
          src={getImageUrl(item.imageUrl, 1)}
          alt={item.name}
        />
        <p className="text-xs mt-2 overflow-hidden break-normal overflow-ellipsis">
          {item.name}
        </p>
      </div>
    </div>
  );
});
