import React from "react";

export function About() {
  return (
    <>
      <div className="container items-center p-2 pb-40">
        <div className="card bg-base-100 card-compact mb-2">
          <div className="card-body info-card">
            <div className="flex justify-center items-center mt-4">
              <img src={process.env.PUBLIC_URL + "trades-logo.png"} alt="Трейдс"/>
            </div>
            <div className="flex justify-between mt-8 border-b pb-3 text-gray-400">
              <div>Версия</div>
              <div>1.1.1</div>
            </div>
            <div className="flex justify-between pb-3 border-b text-gray-400">
              <div>Дата выпуска</div>
              <div>26.10.21</div>
            </div>
            <div className="pb-3 border-b">
              <div>Пользовательские соглашение</div>
            </div>
            <div className="pb-3 border-b">
              <div>Политика конфиденциальности</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
