import { client } from "./client";
export function getProducts(params) {
  return client.post(`/api/public/goods/list`, params);
}

export function searchGoods(params) {
  return client.post(`/api/goods/doSearch`, params);
}

export function goodsById(id) {
  return client.get(`/api/goods/${id}`);
}

export function searchPrices(id) {
  return client.post(`/api/contractor-goods/goods/${id}/doSearch`, {});
}

export function searchContractorGoods(id, params = {}) {
  return client.post(`/api/contractor-goods/contractor/${id}/doSearch`, params);
}
export function goodsFavorites() {
  return client.get(`/api/goods/list/favorite`);
}

export function goodComments(id) {
  return client.post(`/api/goods-review/goods/${id}/doSearch`, {});
}

export function goodRecommendations(id) {
  return client.get(`/api/goods/${id}/recommendations`);
}

export function goodCategories(params) {
  return client.post(`/api/goods-categories/doSearch`, params);
}

export function goodsReview(params) {
  return client.post('/api/goods-review', params);
}
