import { runInAction, makeAutoObservable } from "mobx";
import { merchantAuth, getProfile, logout } from "../api/user";
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';

class UserStore {
  profile = null;
  constructor(rootStore) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'UserStore', properties: ['profile'], storage: window.localStorage });
    this.rootStore = rootStore;
  }

  async fetchAuth(token, categoryType, contractorType) {
    try {
      this.rootStore.app.setLoading(true);
      const response = await merchantAuth(token, categoryType, contractorType);
      if (response.status === 200) {
        const profileResponse = await getProfile();
        runInAction(() => {
          this.profile = profileResponse.data;
          this.rootStore.app.setData(categoryType, contractorType);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.rootStore.app.setLoading(false)
      ;
    }
  }
  async exit() {
    try {
      await logout();
      runInAction(() => {
        this.profile = null;
      })
    } catch (error) {
      // если logout не сработал то просто удаляем стор
      await this.clearStore();
    }
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default UserStore;
