/**
 * Категории 1-го и 2-го уровня
 */

import React, { useState, useRef, useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../shared/funcs";
import { useMst } from "../../store";
import { useLoadCatalog } from "../../shared/hooks";
// import { Icon } from "../../components/icon";

export function TreeCategory({ parentId, contractorId, pathname }) {
  const { app } = useMst();
  const [pageNumber, setPageNumber] = useState(0);
  // const [searchParams] = useSearchParams();
  const { products, hasMore, loading, error } = useLoadCatalog(
    app.categoryType,
    parentId,
    contractorId,
    pageNumber
  );

  const observer = useRef();
  const lastProductRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      {products.map((product, index) => {
        return (
          <Category
            ref={products.length === index + 1 ? lastProductRef : undefined}
            key={product.id}
            item={product}
            contractorId={contractorId}
            pathname={pathname}
          />
        );
      })}
      <div>{loading && "Загрузка..."}</div>
      <div>{error && "Ошибка загрузки"}</div>
    </>
  );
}
TreeCategory.defaultProps = {
  contractorId: undefined,
  pathname: '/product-list'
};

const Category = React.forwardRef(({ item, contractorId, pathname }, ref) => {
  const navigate = useNavigate();
  const toProductList = (id, parentId) => {
    navigate({
      pathname,
      search: createSearchParams({
        categoryId: id,
        parentCategoryId: parentId,
        contractorId,
      }).toString(),
    });
  };
  return (
    <div ref={ref} className="w-full py-4">
      <h2 className="mb-2 text-md font-bold">{item.name}</h2>
      <div className="flex flex-wrap">
        {item.children.map((subItem) => (
          <SubCategory
            key={subItem.id}
            item={subItem}
            parentId={item.id}
            onClick={toProductList}
          />
        ))}
      </div>
    </div>
  );
});

const SubCategory = ({ item, parentId, onClick }) => {
  return (
    <div
      className="w-4/12 p-[5px] h-[110px]"
      onClick={() => onClick(item.id, parentId)}
    >
      <div className="relative w-full h-full p-2 bg-white rounded-xl shadow-md overflow-hidden">
        <p className="text-xs overflow-hidden break-normal overflow-ellipsis">
          {item.name}
        </p>
        <img
          className="absolute h-[80px] w-[80px] bottom-[-10px] right-[-20px] -rotate-[30deg]"
          src={getImageUrl(item.imageUrl, 1)}
          alt={item.name}
        />
      </div>
    </div>
  );
};
