import clsx from "clsx";

export function PageContainer({ children, flex }) {
  return (
    <div
      className={clsx("items-center p-4 pb-20 pt-[60px]", {
        "flex flex-wrap": flex,
      })}
    >
      {children}
    </div>
  );
}
PageContainer.defaultProps = {
  flex: false,
};
