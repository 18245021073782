import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";

export const SearchProducts = ({ toProducts }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVal, setSearchVal] = useState();
  const search = searchParams.get("search");
  useEffect(() => {
    setSearchVal(search);
  }, [search]);
  return (
    <div className="w-full mb-2 ">
      <form
        className="flex flex-row"
        onSubmit={(e) => {
          e.preventDefault();

          const formData = new FormData(e.target);
          const search = formData.get("search");
          if (search) {
            if (toProducts) {
              navigate({
                pathname: '/product-list',
                search: createSearchParams({
                  search,
                }).toString(),
              });
            } else {
              searchParams.set("search", search);
              setSearchParams(searchParams);
            }
          } else {
            if (toProducts) {
            } else {
              searchParams.delete("search");
              setSearchParams(searchParams);
            }
          }
        }}
      >
        <input
          value={searchVal || ""}
          name="search"
          type="text"
          placeholder="Поиск товаров"
          className="input input-bordered w-full"
          onChange={(e) => setSearchVal(e.currentTarget.value)}
        />
        {searchParams.get("search") && (
          <div
            onClick={() => {
              setSearchVal(null);
              searchParams.delete("search");
              setSearchParams(searchParams);
            }}
            className="border border-stone-300 rounded-md w-12 h-12 ml-2 bg-white flex items-center justify-center"
          >
            X
          </div>
        )}
      </form>
    </div>
  );
};
SearchProducts.defaultProps = {
  toProducts: false,
};
