import { Icon } from "./icon";

/**
 * 
 * @param integer rating
 * @returns 
 */
export const RatingStars = ({ stars }) => {
  return (
    <div className="flex">
      {[...Array(stars)].map((s, index) => (
        <Icon  key={`orange_${index}`} name="star" className="h-5 w-5 fill-orange-500" />
      ))}
      {[...Array(5 - stars)].map((s, index) => (
        <Icon key={`gray_${index}`} name="star" className="h-5 w-5 fill-gray-300" />
      ))}
    </div>
  );
};
