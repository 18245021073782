import { makeAutoObservable } from "mobx";
import { nanoid } from "nanoid";
class CartStore {
  productList = [];
  selectContractor = null;
  constructor() {
    makeAutoObservable(this);
  }

  addProduct(data) {
    const cartItem = new CartItem(this, data);
    this.productList.push(cartItem);
    return cartItem;
  }

  addContractor(data) {
    this.selectContractor = data;
  }

  updateProductCount(data) {
    let product = this.productList.find((cartItem) => cartItem.id === data.id);
    if (product) {
      product.updateCount(data.count);
    }
  }
  updateProductPrice(data) {
    console.log('DATA', data)
    let product = this.productList.find((cartItem) => cartItem.good.id === data.id);
    if (product) {
      product.updatePrice(data.price);
    }
  }

  removeProduct(item) {
    this.productList.splice(this.productList.indexOf(item), 1);
  }

  checkIn(productId) {
    let product = this.productList.find(
      (cartItem) => cartItem.good.id === productId
    );
    return product;
  }

  getItemCount(productId) {
    let product = this.productList.find(
      (cartItem) => cartItem.good.id === productId
    );
    return product?.count || 0;
  }
  get total() {
    let total = 0;
    this.productList.forEach((item) => {
      total += item.amount;
    });
    return total;
  }
  get asJSON() {
    return this.productList.map((item) => item.asJSON);
  }

  totalWeightAmountByContractor() {
    let totalW = 0;
    let totalA = 0;
    let products = this.productList.filter((item) => item.contractor.id === this.selectContractor?.id);
    products.forEach((item) => {
      totalA += item.amount;
      totalW += (item.good.unit?.weight || 0) * item.count;
    });
    return {totalW, totalA, products};
  }

  clearProducsByContractor() {
    let products = this.productList.filter((item) => item.contractor.id === this.selectContractor?.id);
    products.forEach(item => item.delete())
    this.selectContractor = null;
  }
}

class CartItem {
  id = null; // uniq item id
  good = null;
  contractor = null;
  count = 0;
  price = null;
  store = null;

  constructor(store, data, id = nanoid()) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });
    this.store = store;
    this.id = id;
    this.good = data.good;
    this.count = data.count;
    this.price = data.price;
    this.contractor = data.contractor;
  }

  get amount() {
    try {
      return this.count * this.price.price;
    } catch (error) {
      return 0;
    }
  }

  updateCount(count) {
    this.count = count;
  }

  updatePrice(price) {
    console.log('update price', price)
    this.price = price;
    this.contractor = price.contractor;
  }

  delete() {
    this.store.removeProduct(this);
  }
  get asJSON() {
    const { id, good, contractor, count, price } = this;
    return {
      id,
      good: { id: good.id, name: good.name, weight: good.unit?.weight },
      contractor: { id: contractor.id, name: contractor.name, department: contractor.department?.id },
      count,
      price: price.price,
    };
  }
}

export default CartStore;
