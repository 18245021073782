import { forwardRef, useCallback, useRef, useState } from "react";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { dateFormat } from "../../shared/funcs";
import { useLoadReviews } from "../../shared/hooks";
import { useMst } from "../../store";
import { Icon } from "../../components/icon";
import { RatingStars } from "../../components/rating-stars";

export function Rating() {
  const { user } = useMst();
  const [pageNumber, setPageNumber] = useState(0);
  const { reviews, loading, error, hasMore } = useLoadReviews(
    user.profile?.contractor?.id,
    pageNumber
  );

  const observer = useRef();
  const lastReviewRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        {reviews.map((item, index) => {
          if (reviews.length === index + 1) {
            return <ReviewItem ref={lastReviewRef} key={item.id} item={item} />;
          } else {
            return <ReviewItem key={item.id} item={item} />;
          }
        })}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка загрузки"}</div>
        {reviews.length === 0 && <div className="flex flex-col items-center mt-10">
        <Icon name="message" className="h-28 w-28 fill-gray-500" />
        <div className="font-semibold text-xl text-gray-400">Отзывов нет</div>
        </div>}
      </PageContainer>
    </>
  );
}

const ReviewItem = forwardRef(({ item }, ref) => {
  return (
    <>
      <div className="card bg-base-100 card-compact mb-2">
        <div className="card-body info-card">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div>
                <div className="font-bold">{item.username}</div>
                <div className="text-xs text-gray-400">{dateFormat(item.createdAt)}</div>
              </div>
            </div>
            <RatingStars stars={item.rating}/>
          </div>
          <div className="text-gray-500">
            {item.message}
          </div>
        </div>
      </div>
    </>
  );
});
