import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { getImageUrl } from "../../../shared/funcs";

export function Slider({ list }) {
  const [sliderRef] = useKeenSlider(
    {
      loop: list.length > 1,
      slides: {
        perView: 1,
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  return (
    <div ref={sliderRef} className="keen-slider info-slider">
      {list.map((item, index) => <SliderItem key={index} item={item} /> )}
    </div>
  );
}

const SliderItem = ({ item }) => {
  return (
    <div className="carousel-item">
      <img
        src={getImageUrl(item.imageUrl, 1)}
        className="keen-slider__slide"
        alt={item.name}
      />
    </div>
  );
};
