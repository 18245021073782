import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { PageContainer } from "../../components/page-container";
import useSeller from "./hooks/useSeller";
import {
  SellerBackground,
  SellerCard,
} from "../../components/sellers/seller-info";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { searchTradeMark } from "../../api/catalog";
import { CACHETIME, PER_PAGE } from "../../shared/constants";
import { useMst } from "../../store";
import { forwardRef, useEffect } from "react";
import { Fragment } from "react";
import { getImageUrl } from "../../shared/funcs";

export function SellerBrands() {
  const [searchParams] = useSearchParams();
  // const parentId = searchParams.get("parentCategoryId");
  const contractorId = searchParams.get("contractorId");
  const { data: seller } = useSeller(contractorId);

  const { app } = useMst();
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const {
    status,
    data,
    error,
    fetchNextPage,
  } = useInfiniteQuery(
    `brands_seller_${contractorId}`,
    async ({ pageParam = 0 }) => {
      const res = await searchTradeMark({
        paging: { page: pageParam, limit: PER_PAGE },
        filters: {
          categoryType: app.categoryType,
          showActive: true,
          contractorId
        },
      });
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const pageCount = Math.ceil(lastPage.totalCount / PER_PAGE);
        const next = lastPage.paging.page + 1;
        return next < pageCount ? next : undefined;
      },
      cacheTime: CACHETIME, 
      staleTime: Infinity,
      retry:false
    }
  );

  const onSelectBrand = (id) => {
    console.log(id);
    navigate({
      pathname: "/sellers/product-list",
      search: createSearchParams({
        brandId: id,
        contractorId,
      }).toString(),
    });
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]); // eslint-disable-line

  return (
    <>
      <SimpleTopNav title={seller.name} />
      {seller && <SellerBackground seller={seller} />}
      <PageContainer>
        {seller && <SellerCard seller={seller} />}

        {status === "loading" ? (
          <p>Загрузка...</p>
        ) : status === "error" ? (
          <span>Ошибка: {error.message}</span>
        ) : (
          <div ref={ref} className="flex flex-wrap">
            {data.pages.map((page, index) => (
              <Fragment key={index}>
                {page.result.map((brand) => (
                  <Brand key={brand.id} item={brand} onClick={() => onSelectBrand(brand.id)} />
                ))}
              </Fragment>
            ))}
          </div>
        )}
        
      </PageContainer>
    </>
  );
}

const Brand = forwardRef(({ item, onClick }, ref) => {
  return (
    <div
      ref={ref}
      className="w-4/12 p-[5px] h-[110px]"
      onClick={() => onClick(item.id)}
    >
      <div className="relative flex flex-col items-center w-full h-full p-2 bg-white rounded-xl shadow-md overflow-hidden">
        <img
          className="h-[60px] w-[60px] object-contain"
          src={getImageUrl(item.imageUrl, 1)}
          alt={item.name}
        />
        <p className="text-xs mt-2 overflow-hidden break-normal overflow-ellipsis">
          {item.name}
        </p>
      </div>
    </div>
  );
});