import { forwardRef, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "../../components/icon";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { useLoadGoodComments } from "../../shared/hooks";
import { RatingStars } from "../../components/rating-stars";
import { useGoodById } from "../../shared/query-hooks";
import { getImageUrl } from "../../shared/funcs";
import { SendComment } from "../../components/send-comment";

export function Comments() {
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState(0);
  const { loading, error, comments, hasMore } = useLoadGoodComments(
    id,
    pageNumber
  );

  const { data: good } = useGoodById(id);

  const observer = useRef();
  const lastCommentRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        <GoodInfo good={good} />
        {!loading && comments.length === 0 && (
          <div className="flex flex-col items-center mt-10">
            <Icon name="message" className="h-28 w-28 fill-gray-500" />
            <div className="font-semibold text-xl text-gray-400">
              Комментариев нет
            </div>
          </div>
        )}
        <div className="mb-20">
          {comments.map((item, index) => {
            if (comments.length === index + 1) {
              return (
                <CommentItem ref={lastCommentRef} key={item.id} item={item} />
              );
            } else {
              return <CommentItem key={item.id} item={item} />;
            }
          })}
          <div>{loading && "Загрузка..."}</div>
          <div>{error && "Ошибка"}</div>
        </div>
      </PageContainer>
      <SendComment entityId={id} />
    </>
  );
}

const CommentItem = forwardRef(({ item }, ref) => {
  return (
    <div className="bg-white border p-2 rounded-xl mb-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div>
            <div className="font-bold">{item.username}</div>
            <div className="text-xs text-gray-400">2022-04-26</div>
          </div>
        </div>
        <div className="font-bold text-xs">
          <RatingStars stars={item.rating || 0} />
        </div>
      </div>
      <div className="text-gray-500">{item.message}</div>
    </div>
  );
});

const GoodInfo = ({ good }) => {
  if (!good) return null;
  return (
    <div className="bg-white border rounded-xl p-2 mb-4 flex">
      <div className="mr-[10px]">
        <img
          src={getImageUrl(good.imageUrl, 1)}
          alt={good.name}
          className="h-[70px] w-[70px] max-w-none"
        />
      </div>
      <div className="w-full flex flex-col justify-between">
        <div>{good.name}</div>
        <div className=" flex flex-row justify-between">
          <div className="font-bold text-gray-600">{good.stars || "0.0"}</div>
          <div className="flex items-center text-orange-400 text-xs">
            <RatingStars stars={parseInt(good.stars || 0, 10)} />
            отзывов ({good.starCount || 0})
          </div>
        </div>
      </div>
    </div>
  );
};
