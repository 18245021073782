import React from "react";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
export function Detail() {
  return (
    <>
      <SimpleTopNav />
      <PageContainer>
        <div className="card bg-base-100 card-compact mb-2">
          <div className="card-body info-card">
            <h3 className="font-bold text-base">О товаре</h3>
            <div className="mt-4">
              <p className="text-gray-400 text-xs font-bold">Артикул:</p>
              <p className="font-bold">111222333</p>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs font-bold">Штрих код:</p>
              <p className="font-bold">111222333</p>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs font-bold">ТНВЕД:</p>
              <p className="font-bold">111222333</p>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs font-bold">ГКЕД:</p>
              <p className="font-bold">111222333</p>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs font-bold">Код маркировки:</p>
              <p className="font-bold">111222333</p>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
}
