import { searchGoods } from "../../../api/product";
import { useMst } from "../../../store";
import { getImageUrl } from "../../../shared/funcs";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { CACHETIME } from "../../../shared/constants";
import { checkUser } from "../../../shared/funcs";

export const PopularGoods = observer(({ title }) => {
  const { app, user } = useMst();

  const {isLoading, isError, data} = useQuery('dashboard_popular', async () => {
    try {
      const response = await searchGoods({
        paging: { page: 0, limit: 6 },
        sorting: { sortBy: "id", sortDirection: "DESC" },
        filters: {
          showDiscount: false,
          showPopular: true,
          showActive: true,
          categoryType: app.categoryType,
        },
      });
      return response.data.result;
    } catch (error) {
      return []
    }
  }, { cacheTime: CACHETIME, staleTime: Infinity, enabled: checkUser(user.profile)})


  if (isLoading || isError || !data || (data && data.length === 0)) return null;

  return (
    <div className="mt-4">
      <h2 className="text-md font-bold">{title}</h2>
      <div className="carousel space-x-4 p-2">
        {data && data.map((item) => (
          <Good key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
});

function Good({ item }) {
  const navigate = useNavigate();
  return (
    <div className="carousel-item">
      <div
        className="bg-base-100 w-40 flex flex-col rounded-box border"
        onClick={() => navigate(`/goods-info/${item.id}`)}
      >
        <div className="p-4  flex justify-center">
          <img
            className="rounded max-h-28"
            src={getImageUrl(item.imageUrl, 1)}
            alt={item.name}
          />
        </div>
        <p className="mt-4 text-center h-12 text-xs text-ellipsis overflow-hidden">
          {item.name}
        </p>

        <div className="px-4 mt-4 mb-1 ">
          <p className="font-bold text-xs price-color">{item.priceRange} c</p>
        </div>
      </div>
    </div>
  );
}
