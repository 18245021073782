import { SimpleTopNav } from "../../components/simple-top-nav";
import { PageContainer } from "../../components/page-container";
import { ZeroCategory } from "../../components/categories/zero";
// import { CatalogItem } from "../../components/catalog-item";
import { TreeCategoryQuery } from "../../components/categories/tree-query";
import { useZeroCategories } from "../../shared/query-hooks";
import { SearchProducts } from "../../components/search-products";

export function Catalog() {
  const { status, data, error } = useZeroCategories();

  return (
    <>
      <SimpleTopNav title="Каталог" />
      <PageContainer>
        <SearchProducts toProducts />
        {/* <div className="flex space-x-2">
          <CatalogItem
            to="/product-list"
            icon="cube_outline"
            text="Все товары"
          />
          <CatalogItem to="/catalog/brands" icon="tag_outline" text="Бренды" />
          <CatalogItem
            to="/product-list?discount=1"
            icon="sale_outline"
            text="Акции"
          />
          <CatalogItem
            to="/product-list?popular=1"
            icon="thumb_up_outline"
            text="Популярные"
          />
        </div> */}
        {status === "loading" ? (
          "Загрузка..."
        ) : status === "error" ? (
          <span>Ошибка: {error.message}</span>
        ) : (
          <>
            {data.length > 1 &&data.map((item) => (
              <ZeroCategory
                key={item.id}
                item={item}
                pathname="/catalog/tree"
              />
            ))}

            {data.length === 1 && (
              <TreeCategoryQuery parentId={data[0].id} pathname="/product-list" />
            )}
          </>
        )}
      </PageContainer>
    </>
  );
}
