import { Link } from "react-router-dom";
import { Icon } from "./icon";

export const CatalogItem = ({ to, icon, text }) => {
  return (
    <Link to={to} className="w-3/12 ">
      <div className="w-full h-full p-2 bg-white border rounded-md overflow-hidden text-[10px] md:text-[14px] items-center flex flex-col">
        <Icon name={icon} className="w-8 h-8 fill-orange-500"/>
        {text}
      </div>
    </Link>
  );
};
