export const CACHETIME = 21600000; // 6 hours
export const CACHE_15_MIN = 900000; // 15 min
export const PER_PAGE = 20;

export const DEALS_STATUSES = [
  { id: "Новый", name: "Новый" },
  { id: "Отправлен", name: "Отправлен" },
  { id: "Принят", name: "Принят" },
  { id: "Отклонен", name: "Отклонен" },
  { id: "Отменен", name: "Отменен" },
];

