import { toast } from "react-toastify";

let successToastId = null;
export function successToast(msg) {
  if (!toast.isActive(successToastId)) {
    successToastId = toast.success(msg);
  }
}

let errToastId = null;
export function errorToast(error) {
  if (error.response) {
    let { data, status } = error.response;
    if (status !== 500) {
      toast.error(`Ошибка: ${data.message}`);
    } else {
      toast.error(`${status} ошибка сервера`);
    }
  } else {
    console.log("Toast id", errToastId, toast.isActive(errToastId));
    if (!toast.isActive(errToastId)) {
      errToastId = toast.error(error.toString());
    }
  }
}
let warnToastId = null;
export function warnToast(msg) {
  if (!toast.isActive(warnToastId)) {
    warnToastId = toast.warn(msg);
  }
}

export function closeAllToast() {
  toast.dismiss();
}
