import { client } from "./client";

export function getTreeCategories(params) {
  return client.post(`/api/goods-categories/tree-view`, params);
}

export function searchTradeMark(params) {
  return client.post(`/api/trade-mark/doSearch`, params);
}

export function tradeMarkById(id) {
  return client.get(`/api/trade-mark/${id}`);
}