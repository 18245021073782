import { Link, useNavigate } from "react-router-dom";
import { dateFormat } from "../../../shared/funcs";
import { useMst } from "../../../store";
import { Icon } from "../../../components/icon";
import { ordersSearch } from "../../../api/order";
import { useQuery } from "react-query";
import { CACHETIME } from "../../../shared/constants";
import { checkUser } from "../../../shared/funcs";
import { observer } from "mobx-react-lite";

export const LastOrders = observer(() => {
  const { user } = useMst();

  const { isLoading, isError, data } = useQuery(
    "dashboard_last_orders",
    async () => {
      try {
        const response = await ordersSearch({
          paging: { page: 0, limit: 6 },
          sorting: { sortBy: "id", sortDirection: "DESC" },
          filters: {
            dealType: "Покупка",
          },
        });
        return response.data.result;
      } catch (error) {
        return [];
      }
    },
    {
      cacheTime: CACHETIME,
      staleTime: Infinity,
      enabled: checkUser(user.profile),
    }
  );

  if (isLoading || isError || !data || (data && data.length === 0)) return null;

  return (
    <div className="mt-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-md font-bold">Последние заказы</h2>
        <Link className="flex items-center" to="/profile/order/history">
          Все <Icon name="chevron_right" className="w-5 h-5 fill-orange-500" />{" "}
        </Link>
      </div>

      <div className="carousel space-x-4 p-2">
        {data && data.map((item) => <Entity key={item.id} item={item} />)}
      </div>
    </div>
  );
});

function Entity({ item }) {
  const navigate = useNavigate();
  return (
    <div className="carousel-item">
      <div
        className="bg-base-100 w-40 flex flex-col rounded-box border"
        onClick={() => navigate(`/profile/order/edit/${item.id}`)}
      >
        {/* <div className="p-4  flex justify-center">
          <img
            className="rounded max-h-28"
            src={getImageUrl(item.imageUrl, 1)}
            alt={item.name}
          />
        </div> */}
        <p className="my-1 text-center text-xs text-ellipsis overflow-hidden">
          {dateFormat(item.createdAt)}
        </p>
        <p className="my-1 font-bold text-center text-xs text-ellipsis overflow-hidden">
          {item.supplier?.name}
        </p>
        <p className="my-1 font-bold text-center text-xs text-ellipsis text-orange-500 overflow-hidden">
          {item.totalAmount || 0} c
        </p>
      </div>
    </div>
  );
}
