import { useEffect, useState, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import JSURL from "jsurl";
import uniqBy from "lodash.uniqby";
import { client } from "../api/client";
import {
  goodComments,
  searchContractorGoods,
  searchGoods,
} from "../api/product";
import { searchContractor, searchContractorReview } from "../api/contractor";
import { bonusSearch, ordersSearch } from "../api/order";
import { searchFaqs } from "../api/user";
import { searchTradeMark } from "../api/catalog";

export function useQueryParam(key) {
  let [searchParams, setSearchParams] = useSearchParams();
  let paramValue = searchParams.get(key);

  let value = useMemo(() => JSURL.parse(paramValue), [paramValue]);

  let setValue = useCallback(
    (newValue, options) => {
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSURL.stringify(newValue));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
}

export function useLoadCatalog(categoryType, parentId,contractorId, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setProducts([]);
  }, [categoryType, parentId, contractorId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let params = {
      paging: { page: pageNum, limit: 20 },
      filters: { categoryType, parentId, contractorId, showActive: true, },
    };
    const controller = new AbortController();
    client
      .post("/api/goods-categories/tree-view", params, {
        signal: controller.signal,
      })
      .then((res) => {
        setProducts((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setHasMore(res.data.result.length > res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        // if (client.isCancel(e)) return;
        console.error(e);
        setError(true);
      });
    return () => controller.abort();
  }, [categoryType,parentId,contractorId, pageNum]);
  return { loading, error, products, hasMore };
}

export function useLoadProducts(search, categoryId, categoryType, tradeMarkId, discount, popular, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setProducts([]);
    setTotalCount(0);
  }, [search, categoryId, categoryType, tradeMarkId, discount, popular]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      filters: {
        searchParameter: search,
        categoryId,
        categoryType,
        tradeMarkId,
        showDiscount: Boolean(discount),
        showPopular: Boolean(popular),
        showActive: true,
      },
    };
    searchGoods(params)
      .then((res) => {
        setProducts((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
        if (res.data.result.length === 0) {
          setHasMore(false);
        }
      })
      .catch((e) => {
        // if (client.isCancel(e)) return;
        console.error(e);
        setError(true);
      });
  }, [search, categoryId, categoryType, tradeMarkId, discount, popular, pageNum]);

  useEffect(() => {
    // console.log('prods', products.length, totalCount)
    setHasMore(products.length === 0 || products.length < totalCount);
    // setLoading(false);
  }, [products, totalCount]);

  return { loading, error, products, hasMore };
}

export function useLoadSellers(searchParameter, categoryType, market, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setSellers([]);
  }, [searchParameter, categoryType, market]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      filters: {
        searchParameter,
        categoryType,
        marketplaceId: market ? +market : null,
        showActive: true,
      },
    };
    searchContractor(params)
      .then((res) => {
        setSellers((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [searchParameter, categoryType, market, pageNum]);

  useEffect(() => {
    setHasMore(sellers.length < totalCount);
  }, [sellers, totalCount]);

  return { loading, error, sellers, hasMore };
}

export function useLoadContractorProducts(contractorId, categoryId, tradeMarkId, searchParameter, showDiscount,partnerId, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setProducts([]);
  }, [contractorId, categoryId, tradeMarkId, searchParameter, showDiscount]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      sorting: { sortBy: "id", sortDirection: "DESC" },
      filters: { categoryId, tradeMarkId, priceTypeId: 1, searchParameter, showDiscount, partnerId },
    };
    searchContractorGoods(contractorId, params)
      .then((res) => {
        setProducts((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [contractorId, categoryId, tradeMarkId, searchParameter, showDiscount, partnerId, pageNum]);

  useEffect(() => {
    setHasMore(products.length < totalCount);
  }, [products, totalCount]);

  return { loading, error, products, hasMore };
}

export function useLoadOrderHistory(contractorId, status, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setOrders([]);
  }, [contractorId, status]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      sorting: { sortBy: "id", sortDirection: "DESC" },
      filters: { dealType: "Покупка", statusList: status ? [status] : undefined, },
    };
    ordersSearch(params)
      .then((res) => {
        setOrders((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [contractorId, status, pageNum]);

  useEffect(() => {
    setHasMore(orders.length < totalCount);
  }, [orders, totalCount]);

  return { loading, error, orders, hasMore };
}

export function useLoadBonus(pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setBonuses([]);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      sorting: { sortBy: "id", sortDirection: "DESC" },
    };
    bonusSearch(params)
      .then((res) => {
        setBonuses((prev) => {
          const items = [...prev, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [pageNum]);

  useEffect(() => {
    setHasMore(bonuses.length < totalCount);
  }, [bonuses, totalCount]);

  return { loading, error, bonuses, hasMore };
}

export function useLoadReviews(contractorId, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setReviews([]);
  }, [contractorId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      sorting: { sortBy: "id", sortDirection: "DESC" },
    };
    searchContractorReview(contractorId, params)
      .then((res) => {
        setReviews((prevProducts) => {
          const items = [...prevProducts, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [contractorId, pageNum]);

  useEffect(() => {
    setHasMore(reviews.length < totalCount);
  }, [reviews, totalCount]);

  return { loading, error, reviews, hasMore };
}

export function useLoadFaqs(pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setFaqs([]);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
    };
    searchFaqs(params)
      .then((res) => {
        setFaqs((prevs) => {
          const items = [...prevs, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [pageNum]);

  useEffect(() => {
    setHasMore(faqs.length < totalCount);
  }, [faqs, totalCount]);

  return { loading, error, faqs, hasMore };
}

export function useLoadGoodComments(goodId, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setComments([]);
  }, [goodId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    goodComments(goodId)
      .then((res) => {
        setComments((prevs) => {
          const items = [...prevs, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [goodId, pageNum]);

  useEffect(() => {
    setHasMore(comments.length < totalCount);
    // setLoading(false);
  }, [comments, totalCount]);

  return { loading, error, comments, hasMore, totalCount };
}


export function useLoadContractorReviews(id, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setComments([]);
  }, [id]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      sorting: { sortBy: 'id', sortDirection: 'DESC' },
    };
    searchContractorReview(id, params)
      .then((res) => {
        setComments((prevs) => {
          const items = [...prevs, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [id, pageNum]);

  useEffect(() => {
    setHasMore(comments.length < totalCount);
    // setLoading(false);
  }, [comments, totalCount]);

  return { loading, error, comments, hasMore, totalCount };
}

export function useLoadBrands(categoryType, pageNum) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [brands, setBrands] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setBrands([]);
    setTotalCount(0);
  }, [categoryType]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const params = {
      paging: { page: pageNum, limit: 20 },
      filters: {
        categoryType,
        showActive: true,
      },
    };
    searchTradeMark(params)
      .then((res) => {
        setBrands((prevs) => {
          const items = [...prevs, ...res.data.result];
          return uniqBy(items, function (item) {
            return item.id;
          });
        });
        setTotalCount(res.data.totalCount);
        setLoading(false);
        if (res.data.result.length === 0) {
          setHasMore(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [categoryType, pageNum]);

  useEffect(() => {
    setHasMore(brands.length === 0 || brands.length < totalCount);
  }, [brands, totalCount]);

  return { loading, error, brands, hasMore };
}