import { client } from "./client";

export function merchantAuth(token, categoryType, contractorType) {
  return client.post(
    `/auth/login/merchantAuth`,
    null,
    {
      headers: {
        "x-token-authorization": token,
        "x-category-type": categoryType,
        "x-contractor-type": contractorType,
      },
      withCredentials: true,
    }
  );
}

export function standartLogin() {
  const formData = "username=admin@python.com&password=python";
  return client.post(`/auth/login`, formData, {
    withCredentials: true,
  });
}

export function getProfile() {
  return client.get(`/api/accounts/me`);
}

export function logout() {
  return client.get(`/logout`);
}

export function searchFaqs(params) {
  return client.post(`/api/faq/doSearch`, params)
}