import { Link, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../shared/funcs";
import { Icon } from "../icon";
import { IconClock, IconLocationMarker, IconPhone } from "../icons/icons";

export const SellerCard = ({ seller }) => {
  const navigate = useNavigate();
  const showRews = (id) => {
    navigate(`/sellers/reviews/${id}`);
  };
  return (
    <div className="bg-white rounded-md p-2 shadow-md mb-2 relative mt-10">
      <div
        className="absolute rounded-full border h-14 w-14 bg-white bg-no-repeat bg-cover -top-5"
        style={{
          backgroundImage: `url(${getImageUrl(seller.logoUrl, 1)})`,
        }}
      ></div>
      <div className="text-center mb-4">{seller.name}</div>
      <div className="flex flex-row justify-between mx-4">
        <div className="flex flex-row items-center">
          <div className="text-orange-500">
            <IconLocationMarker />
          </div>
          <div className="ml-2 text-xs max-w-[150px]">
            {seller.address?.description ? (
              seller.address?.description
            ) : (
              <>
                <div className="text-xs">
                  Рынок:{" "}
                  {seller.address?.marketplace
                    ? seller.address?.marketplace?.name
                    : "----"}
                </div>
                <div className="text-xs">
                  Ряд:{" "}
                  {seller.address?.aisle ? seller.address?.aisle.name : "----"}
                </div>
                <div className="text-xs">
                  Контейнер: {seller.address?.house || "----"}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center text-xs p-1 ml-2">
          <span className="text-orange-500">
            <IconClock />
          </span>
          <span className="ml-2">
            {seller.schedule ? seller.schedule : "Не указано"}
          </span>
        </div>
      </div>
      {seller.department?.phone && (
        <div className="flex flex-row justify-between mt-4 ">
          <Link
            className="shadow-md p-2 flex items-center rounded-xl"
            to={`https://api.whatsapp.com/send?phone=${seller.department?.phone}`}
          >
            <svg className="fill-orange-500 mr-2 h-4 w-4">
              <use
                xlinkHref={`${process.env.PUBLIC_URL}/images/icons/sprite.svg#whatsapp`}
              ></use>
            </svg>
            Написать
          </Link>
          <Link
            className="shadow-md p-2 flex items-center rounded-xl"
            to={`tel:${seller.department?.phone}`}
          >
            <span className="text-orange-500 mr-2">
              <IconPhone />
            </span>
            Позвонить
          </Link>
        </div>
      )}
      <div className="my-2">
        <div
          className="flex flex-row justify-center text-xs p-1 rounded-xl"
          onClick={(e) => {
            e.stopPropagation();
            showRews(seller.id);
          }}
        >
          <Icon name="thumb_up" className="h-4 w-4 fill-orange-500" />
          <div className="text-black">Отзывы ({seller.starCount || 0})</div>
        </div>
      </div>
    </div>
  );
};

export const SellerBackground = ({ seller }) => {
  return (
    <div
      className="h-40 w-full bg-cover absolute top-12"
      style={{
        backgroundImage: seller.imageUrl
          ? `url(${getImageUrl(seller.imageUrl, 1)})`
          : `url(/images/no-background.webp)`,
      }}
    ></div>
  );
};
