// import isEmpty from "lodash/isEmpty";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconClock } from "../../components/icons/icons";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
// import { getImageUrl } from "../../shared/funcs";
import { useLoadSellers } from "../../shared/hooks";
import { useMst } from "../../store";
import { Icon } from "../../components/icon";
import isEmpty from "lodash/isEmpty";
import { getImageUrl } from "../../shared/funcs";
import { marketplaceSearch } from "../../api/contractor";
import clsx from "clsx";

export function Sellers() {
  const { app } = useMst();
  const [pageNumber, setPageNumber] = useState(0);
  const [markets, setMarkets] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVal, setSearchVal] = useState();
  const searchParameter = searchParams.get("filter");
  const { sellers, hasMore, loading, error } = useLoadSellers(
    searchParameter,
    app.categoryType,
    searchParams.get("market"),
    pageNumber
  );

  const observer = useRef();
  const lastSellerRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        if (app.categoryType === "Стройматериалы") {
          const response = await marketplaceSearch({
            paging: { page: 0, limit: 80 },
          });
          setMarkets(response.data.result);
        }
      } catch (error) {
        // nothing
      }
    };
    fetchMarkets();
  }, [app.categoryType]);

  useEffect(() => {
    setSearchVal(searchParameter);
  }, [searchParameter]);

  const showSeller = (id) => {
    navigate(`/sellers/info/${id}`);
  };

  const showRews = (id) => {
    navigate(`/sellers/reviews/${id}`);
  };
  const onSelectMarket = (id) => {
    const selected = parseInt(searchParams.get("market"), 10);
    if (selected === id) {
      searchParams.delete("market");
    } else {
      searchParams.set("market", id);
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <SimpleTopNav title="Продавцы" />
      <PageContainer>
        <form
          className="mb-2 flex flex-row"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const search = formData.get("search");
            console.log(formData.get("search"));
            if (search) {
              searchParams.set("filter", search);
              setSearchParams(searchParams);
            } else {
              searchParams.delete("filter");
              setSearchParams(searchParams);
            }
          }}
        >
          <input
            value={searchVal || ""}
            name="search"
            type="text"
            placeholder="Поиск продавцов"
            className="input input-bordered w-full"
            onChange={(e) => setSearchVal(e.currentTarget.value)}
          />
          {searchParams.get("filter") && (
            <div
              onClick={() => {
                setSearchVal(null);
                searchParams.delete("filter");
                setSearchParams(searchParams);
              }}
              className="border border-stone-300 rounded-md w-12 h-12 ml-2 bg-white flex items-center justify-center"
            >
              X
            </div>
          )}
        </form>

        <Markets
          markets={markets}
          onClick={onSelectMarket}
          market={searchParams.get("market")}
        />

        {sellers.map((item, index) => (
          <SellerItem
            key={item.id}
            ref={sellers.length === index + 1 ? lastSellerRef : undefined}
            item={item}
            showSeller={showSeller}
            showRews={showRews}
          />
        ))}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка"}</div>
      </PageContainer>
    </>
  );
}

const SellerItem = forwardRef(({ item, showSeller, showRews }, ref) => {
  const withImage = !isEmpty(item.imageUrl);
  return (
    <>
      <div
        ref={ref}
        onClick={() => showSeller(item.id)}
        className="bg-white border p-2 flex flex-col items-center rounded-md mb-2 min-h-[180px] relative bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: withImage
            ? `url(${getImageUrl(item.imageUrl, 1)})`
            : "url(/images/no-background.webp)",
        }}
      >
        <div className="m-5 p-4 bg-black/40 text-center break-words w-3/5 rounded-xl text-white">
          {item.name}
          <p className="text-xs">
            {item.description ? item.description : "Описание отсутствует"}
          </p>
          <p className="text-xs">
            {(item.address &&
              item.address.marketplace &&
              `Адрес: ${item.address.marketplace.name}`) ||
              "Адрес: отсутствует"}
          </p>
        </div>
        <div className="w-full flex justify-between px-2  mt-2">
          {item.distance && (
            <div className="flex flex-row items-center text-xs py-1 px-2 rounded-xl bg-black/40 text-white">
              {item.distance || 0} м
            </div>
          )}
          <div className="flex flex-row items-center text-xs py-1 px-2 rounded-xl bg-black/40">
            <span className="text-orange-500">
              <IconClock />
            </span>
            <span className="ml-2 text-white">
              {item.schedule ? item.schedule : "Не указано"}
            </span>
          </div>
          <div className="flex flex-row space-x-1">
            <div
              className="flex flex-row items-center text-xs py-1 px-2 rounded-xl bg-black/40"
              onClick={(e) => {
                e.stopPropagation();
                showRews(item.id);
              }}
            >
              <Icon name="thumb_up" className="h-4 w-4 fill-orange-500" />
              <div className="text-white">Отзывы ({item.starCount || 0})</div>
            </div>
            <div className="flex flex-row items-center text-xs py-1 px-2 rounded-xl bg-black/40">
              <Icon name="star" className="h-4 w-4 fill-orange-500" />
              <div className="text-white"> ({item.star || 0})</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

const Markets = ({ markets, onClick, market }) => {
  useEffect(() => {
    if (markets.length > 0 && market) {
      const carousel = document.getElementById("mts");
      const target = document.getElementById(`mts_${market}`);
      const left = target.offsetLeft - 50;
      carousel.scrollTo({ left: left });
    }
  }, [markets, market]);
  if (markets.length === 0) return null;
  return (
    <div className="bg-white border rounded-md mb-2">
      <div id="mts" className="carousel space-x-4 p-2">
        {markets.map((item) => (
          <div
            key={item.id}
            id={`mts_${item.id}`}
            onClick={() => onClick(item.id)}
            className={clsx(
              "border rounded-md w-auto bg-white",
              "flex flex-row whitespace-nowrap py-1 px-2",
              { "bg-orange-100": +market === item.id }
            )}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};
