import { useMst } from "../../store";
import { Icon } from "../icon";

export function TopNav() {
  return (
    <div className="fixed w-full z-20 bg-base-100 overflow-y-hidden top-0 inset-x-0">
      <div className="flex items-center justify-between w-full h-[54px]">
        <div className="w-14" />
        <div className="text-bold text-md font-semibold text-center">
          Trades
        </div>
        <div className="w-14">
          <CloseBtn />
        </div>
      </div>
    </div>
  );
}
const CloseBtn = () => {
  const { user } = useMst();
  return (
    <a
      href="native://goBack"
      onClick={() => user.exit()}
      className="bg-slate-300 w-12 flex items-center justify-center rounded-md mr-3"
    >
      <Icon name="close" className="h-8 w-8 fill-white" />
    </a>
  );
};
