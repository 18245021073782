import React, { useState } from "react";
import { Slider } from "./components/slider";
import "./components/style.css";
import { useParams } from "react-router-dom";
import { searchPrices } from "../../api/product";
import "./components/style.css";
import {
  IconAnnotation,
  IconChevronRight,
  IconClock,
  IconLocationMarker,
  IconMinus,
  IconPhone,
  IconPlus,
} from "../../components/icons/icons";
import { getImageUrl } from "../../shared/funcs";
// import { GoodsInfoTopNav } from "./components/goods-info-top-nav";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useMst } from "../../store";
import { observer } from "mobx-react-lite";
import { client } from "../../api/client";
import { Recomendations } from "../../components/goods-info/recommedations";
import { Icon } from "../../components/icon";
import { errorToast, warnToast, closeAllToast } from "../../shared/toast";
import { useGoodById } from "../../shared/query-hooks";
import { SimpleTopNav } from "../../components/simple-top-nav";

import { IconHeart, IconHeartFilled } from "../../components/icons/icons";
import { PageContainer } from "../../components/page-container";

export const GoodsInfo = observer(() => {
  const { id } = useParams();
  // const [good, setGood] = useState();
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const { cart } = useMst();

  const { data: good } = useGoodById(id);
  React.useEffect(() => {
    if (good !== undefined) {
      setIsFavorite(good.isFavorite);
    }
  }, [good]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await searchPrices(id);
        const { result } = response.data;
        setPrices(result);
      } catch (error) {
        // do nothing
      }
    };
    fetchData();
    return () => {
      closeAllToast();
    }
  }, [id]);

  // после загрузки цен
  React.useEffect(() => {
    const checkPrices = () => {
      try {
        if (prices.length > 0) {
          const cartItem = cart.checkIn(+id);
          const priceInCart = prices.find(
            (item) => item.id === cartItem?.price?.id
          );
          if (priceInCart) {
            setSelectedPrice(priceInCart);
          }
        }
      } catch (error) {
        // do nothing
      }
    };
    checkPrices();
  }, [prices, id, cart]);

  const handlePlus = () => {
    if (selectedPrice) {
      const cartItem = cart.checkIn(good.id);
      if (cartItem) {
        // check item in cart
        cartItem.updateCount(cartItem.count + 1);
      } else {
        // new item
        const data = {
          good,
          contractor: selectedPrice.contractor,
          price: selectedPrice,
          count: 1,
        };
        cart.addProduct(data);
      }
    } else {
      warnToast("Выберите продавца");
    }
  };
  const handleMinus = () => {
    const cartItem = cart.checkIn(good.id);
    if (cartItem.count === 1) {
      // delete item
      cartItem.delete();
    } else {
      // update item
      cartItem.updateCount(cartItem.count - 1);
    }
  };

  const selectPrice = (price) => {
    if (price.status === "AVAILABLE" && price.price > 0) {
      cart.updateProductPrice({ id: good.id, price });
      setSelectedPrice(price);
    } else {
      errorToast("Нельзя выбрать этого продавца");
    }
  };

  const onAddFavorite = async () => {
    try {
      if (isFavorite) {
        await client.delete(`api/goods/${good.id}/remove-favorite`);
        setIsFavorite(false);
      } else {
        await client.get(`api/goods/${good.id}/add-favorite`);
        setIsFavorite(true);
      }
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <>
      {/* <GoodsInfoTopNav favorite={isFavorite} onAddFavorite={onAddFavorite} /> */}
      <SimpleTopNav title={good && good.name} />

      {good && (
        <>
          <PageContainer>
            <div className="card bg-base-100 card-compact ">
              <figure>
                <Slider list={good.imageList} />
              </figure>
              <div className="card-body info-card relative">
                <button
                  className="px-4 absolute right-0"
                  onClick={() => onAddFavorite()}
                >
                  <div className="text-orange-500">
                    {isFavorite ? <IconHeartFilled /> : <IconHeart />}
                  </div>
                </button>
                <div className="flex justify-between">
                  <p className="color-gray text-xm">
                    {good && good.category?.name}
                  </p>
                </div>
                <h2 className="card-title text-2xl">{good && good.name}</h2>

                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center text-sm text-orange-500">
                    <Icon name="star" fill="fill-orange-500" />
                    {good && (good.stars || "0.0")}
                    <div className="ml-1">
                      отзывов ({good && (good.starCount || 0)})
                    </div>
                  </div>
                  <div className="text-lg py-2">
                    <div className="flex flex-row space-x-2 w-full items-center justify-end">
                      <button
                        className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
                        onClick={() => handleMinus()}
                      >
                        <IconMinus />
                      </button>
                      <p> {cart.getItemCount(good.id)} </p>
                      <button
                        className="focus:outline-none border rounded-md text-white font-bold py-1 px-1 inline-flex items-center active:bg-orange-50"
                        onClick={() => handlePlus()}
                      >
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </div>

                <p className="color-gray text-xs">Выберите продавца</p>

                {prices.map((item) => (
                  <ContractorPrice
                    item={item}
                    key={item.id}
                    selected={selectedPrice}
                    onClick={selectPrice}
                  />
                ))}

                {/* <div className="card-actions justify-end">
              <button onClick={handleClick} className="btn btn-ghost">
                {hide ? "Скрыть" : "Показать всех"}
              </button>
            </div> */}

                <div className="divider"></div>
                <div>
                  <h2 className="card-title text-xl">Описание</h2>
                  <p>{good && good.description}</p>
                </div>
                <div className="divider"></div>
                <Link to={`/comments/${id}`}>
                  <button className="btn btn-block flex justify-between btn-ghost pl-0 pr-0">
                    <div className="flex items-center">
                      <div className="mr-4 text-slate-400">
                        <IconAnnotation />
                      </div>
                      <div>Отзывы ({good && (good.starCount || 0)})</div>
                    </div>
                    <div className="text-slate-400">
                      <IconChevronRight />
                    </div>
                  </button>
                </Link>

                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box mb-2 mt-2"
                >
                  <input type="checkbox" className="peer" />
                  <div className="collapse-title text-lg font-medium">
                    Подробнее
                  </div>
                  <div className="collapse-content">
                    <div className="mt-4">
                      <p className="text-gray-400 text-xs font-bold">
                        Артикул:
                      </p>
                      <p className="font-bold">{good.article || "---"}</p>
                    </div>
                    <div className="mt-4">
                      <p className="text-gray-400 text-xs font-bold">
                        Штрих код:
                      </p>
                      <p className="font-bold">{good.barcode || "---"}</p>
                    </div>
                    <div className="mt-4">
                      <p className="text-gray-400 text-xs font-bold">ТНВЕД:</p>
                      <p className="font-bold">{good.fea?.rate || "---"}</p>
                    </div>
                    <div className="mt-4">
                      <p className="text-gray-400 text-xs font-bold">ГКЕД:</p>
                      <p className="font-bold">{good.cea?.name || "---"}</p>
                    </div>
                    <div className="mt-4">
                      <p className="text-gray-400 text-xs font-bold">
                        Код маркировки:
                      </p>
                      <p className="font-bold">{good.markingCode || "---"}</p>
                    </div>
                  </div>
                </div>
                {/* <Link to="/detail">
              <button className="btn btn-block flex justify-between btn-ghost pl-0 pr-0">
                <div className="flex items-center">
                  <div className="mr-4 text-slate-400">
                    <IconInformationCircle />
                  </div>
                  <div>Подробнее</div>
                </div>
                <div className="text-slate-400">
                  <IconChevronRight />
                </div>
              </button>
            </Link> */}
              </div>
            </div>
            <Recomendations goodId={id} />
          </PageContainer>
        </>
      )}
    </>
  );
});

const ContractorPrice = ({ item, selected, onClick }) => {
  return (
    <div
      className={clsx("flex justify-between border-2 rounded-xl p-2", {
        "border-green-500": selected?.id === item.id,
      })}
      onClick={() => onClick(item)}
    >
      <div className="flex">
        <div className="mr-2 flex flex-col">
          <div className="w-16 h-16 rounded-full">
            <img
              src={getImageUrl(item.contractor.imageUrl, 1)}
              alt={item.contractor?.name}
            />
          </div>
          <div className="flex flex-col left-0 text-center w-full font-bold price-color mt-2">
            <div className="relative">
              <span className={clsx({ discount: item.discount })}>
                {item.price} с
              </span>
            </div>
            {item.discount && <span>{item.discount} %</span>}
            {item.discount && <span>{item.discountPrice} c</span>}
          </div>
        </div>

        <div className="info-contact">
          <p className="font-bold mb-1 text-xl">{item.contractor?.name}</p>
          <p>
            <IconPhone />
            {item.contractor?.phone || "---"}
          </p>
          <p>
            <IconLocationMarker />
            {item.contractor?.address?.description || "---"}
          </p>
          <p>
            <IconClock />
            {item.contractor?.schedule || "---"}
          </p>
          <QuickOrderStatus status={item.status} />
        </div>
      </div>

      {/* <div>
        <div
          className="dropdown dropdown-end"
          onClick={(e) => e.stopPropagation()}
        >
          <label tabIndex="0" className="btn btn-ghost btn-circle m-1">
            <IconInformationCircle />
          </label>
          <ul
            tabIndex="0"
            className="dropdown-content menu p-2 border shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <div className="active:bg-orange-500">
                <IconPhone />
                Позвонить
              </div>
            </li>
            <li>
              <div className="active:bg-orange-500">
                <IconChat />
                Написать
              </div>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

function QuickOrderStatus({ status }) {
  let stats;
  switch (status) {
    case "AVAILABLE":
      stats = <span className="text-green-500">доступен</span>;
      break;
    case "NOT_AVAILABLE":
      stats = <span className="text-yellow-500">временно не доступен</span>;
      break;
    case "NOT_ACTIVE":
      stats = <span className="text-red-700">не доступен</span>;
      break;
    default:
      stats = <span className="text-red-700">не доступен</span>;
      break;
  }
  return stats;
}
