import { forwardRef, useCallback, useRef, useState } from "react";
import { PageContainer } from "../../components/page-container";
import { SimpleTopNav } from "../../components/simple-top-nav";
import { Icon } from "../../components/icon";
import { useLoadFaqs } from "../../shared/hooks";

export function Faq() {
  const [pageNumber, setPageNumber] = useState(0);
  const { faqs, loading, error, hasMore } = useLoadFaqs(pageNumber);

  const observer = useRef();
  const lastFaqsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <SimpleTopNav title="Вопросы и ответы" />
      <PageContainer>
        {faqs.map((item, index) => {
          if (faqs.length === index + 1) {
            return <FaqItem ref={lastFaqsRef} key={item.id} item={item} index={index} />;
          } else {
            return <FaqItem key={item.id} item={item} index={index} />;
          }
        })}
        <div>{loading && "Загрузка..."}</div>
        <div>{error && "Ошибка загрузки"}</div>
        {faqs.length === 0 && (
          <div className="flex flex-col items-center mt-10">
            <Icon name="message" className="h-28 w-28 fill-gray-500" />
            <div className="font-semibold text-xl text-gray-400">
              Вопросов нет
            </div>
          </div>
        )}
      </PageContainer>
    </>
  );
}

const FaqItem = forwardRef(({ item, index }, ref) => {
  return (
    <div
      ref={ref}
      tabIndex={index}
      className="collapse collapse-arrow shadow border-base-300 bg-base-100 rounded-box mb-2 mt-2"
    >
      <div className="collapse-title text-lg font-medium">
        {item.title}
      </div>
      <div className="collapse-content">
        <p>
          {item.description}
        </p>
      </div>
    </div>
  );
});
